import React, { useState } from 'react';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import firebase from 'firebase/app';
import swal from 'sweetalert';

export default function FIUnit({ data }) {
   const [loading, setLoading] = useState(false);
   const [availableUnits, setAvailableUnits] = useState(data.availableUnits);

   const onUpdate = async () => {
      try {
         setLoading(true);
         let fractionalInvestmentDurationCollection = '';
         switch (data.noOfYears) {
            case 4:
               fractionalInvestmentDurationCollection =
                  'fractionalPropertiesFourYears';
               break;
            case 5:
               fractionalInvestmentDurationCollection =
                  'fractionalPropertiesFiveYears';
               break;
            default:
               fractionalInvestmentDurationCollection =
                  'fractionalPropertiesFourYears';
         }

         await firebase
            .firestore()
            .collection(fractionalInvestmentDurationCollection)
            .doc(data.id)
            .update({
               availableUnits,
               updatedAt: new Date().toISOString(),
            });

         setLoading(false);
         swal('Success', 'Unit updated successfully', 'success');
      } catch (error) {
         console.log(error);
         setLoading(false);
         swal('Error', 'Something went wrong', 'error');
      }
   };

   return (
      <Row>
         <Col lg="12">
            <h4 className="mb-2">{data.name}</h4>
         </Col>
         <Col lg="4">
            <FormGroup id="yearsD">
               <label className="form-control-label">Number of Years</label>
               <input
                  className="form-control-alternative form-control"
                  defaultValue={data.noOfYears}
                  disabled
               />
            </FormGroup>
         </Col>
         <Col lg="4">
            <FormGroup id="yearsD">
               <label className="form-control-label">
                  Fractional Unit Plan
               </label>
               <input
                  className="form-control-alternative form-control"
                  defaultValue={`${data.fractionalUnitPlanPercentage}%`}
                  disabled
               />
            </FormGroup>
         </Col>
         <Col lg="4">
            <FormGroup>
               <label className="form-control-label">
                  Annual Yield Percentage
               </label>
               <input
                  className="form-control-alternative form-control"
                  defaultValue={`${data.annualYieldPercentage}%`}
                  disabled
               />
            </FormGroup>
         </Col>
         <Col lg="4">
            <FormGroup>
               <label className="form-control-label">
                  Capital gain Percentage
               </label>
               <input
                  className="form-control-alternative form-control"
                  defaultValue={`${data.capitalGainPercentage}%`}
                  disabled
               />
            </FormGroup>
         </Col>
         <Col lg="4">
            <FormGroup id="yearsD">
               <label className="form-control-label">Available Units</label>
               <input
                  className="form-control-alternative form-control"
                  value={availableUnits}
                  onChange={(e) => setAvailableUnits(Number(e.target.value))}
                  type="number"
               />
            </FormGroup>
         </Col>
         <Col lg="12" className="d-flex justify-content-end">
            <Button
               color="success"
               type="submit"
               disabled={loading || availableUnits === data.availableUnits}
               onClick={onUpdate}
            >
               {loading ? (
                  <>
                     Updating... <i className="fa fa-spinner fa-spin"></i>
                  </>
               ) : (
                  'Update'
               )}
            </Button>
         </Col>
      </Row>
   );
}
