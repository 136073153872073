import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
   Alert,
   Button,
   Col,
   Form,
   FormGroup,
   Row,
   Spinner,
   TabPane,
   UncontrolledAlert,
} from 'reactstrap';
import firebase from 'firebase/app';
import swal from 'sweetalert';
import FIUnit from './FIUnit';

export default function FIForm({
   activeTab,
   pid,
   name,
   price,
   state,
   beds,
   city,
   type,
   currentUser,
   primaryPhoto,
}) {
   const [showAddUnitForm, setShowAddUnitForm] = useState(false);
   const [addUnitLoading, setAddUnitLoading] = useState(false);
   const [fetchFhiLoading, setFetchFhiLoading] = useState(true);
   const [fhiData, setFhiData] = useState([]);
   const [fetchError, setFetchError] = useState(false);

   console.log('fhiData', fhiData);

   const { register, handleSubmit, watch, setValue, errors } = useForm();

   const fetchPropertyFractionalInvestments = async () => {
      try {
         setFetchFhiLoading(true);
         const snapshot = await firebase
            .firestore()
            .collection('fractionalPropertiesFourYears')
            .where('pid', '==', pid)
            .orderBy('createdAt', 'asc')
            .get();

         if (snapshot.empty) {
            setFetchFhiLoading(false);
            return;
         }
         const FI_4Y_Data = [];
         snapshot.forEach(async (doc) => {
            FI_4Y_Data.push(doc.data());
         });
         setFhiData(FI_4Y_Data);
         setFetchFhiLoading(false);
      } catch (error) {
         setFetchError(true);
         setFetchFhiLoading(false);
         console.log('error', error);
      }
   };

   const onSubmit = async (data) => {
      try {
         const unitName = `${name} - ${data.unitName}`;
         const nameExist = fhiData.find((item) => item.name === unitName);
         console.log('nameExist', nameExist);
         if (nameExist) {
            swal('Error', 'Unit name already exist', 'error');
            return;
         }
         setAddUnitLoading(true);
         console.log(data);
         const fhiUnitPrice =
            price / (100 / Number(data.fractionalUnitPlanPercentage));
         const annualYield =
            (Number(data.annualYieldPercentage) / 100) * fhiUnitPrice;
         const capitalGain =
            (Number(data.capitalGainPercentage) / 100) * fhiUnitPrice;

         const payload = {
            fractionalUnitPlanPercentage: Number(
               data.fractionalUnitPlanPercentage
            ),
            name: unitName,
            pid,
            noOfYears: Number(data.noOfYears),
            availableUnits: Number(data.availableUnits),
            noOfFractionalUnits: Number(data.availableUnits),
            capitalGainPercentage: Number(data.capitalGainPercentage),
            annualYieldPercentage: Number(data.annualYieldPercentage),
            price,
            city,
            state,
            type,
            beds,
            primaryPhoto,
            fhiUnitPrice,
            annualYield,
            capitalGain,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            createdBy: currentUser.currentUserEmail,
         };

         console.log('payload', payload);

         let fractionalInvestmentDurationCollection = '';
         switch (data.noOfYears) {
            case 4:
               fractionalInvestmentDurationCollection =
                  'fractionalPropertiesFourYears';
               break;
            case 5:
               fractionalInvestmentDurationCollection =
                  'fractionalPropertiesFiveYears';
               break;
            default:
               fractionalInvestmentDurationCollection =
                  'fractionalPropertiesFourYears';
         }

         const batch = firebase.firestore().batch();

         // batch.update(firebase.firestore().collection('property').doc(pid), {
         //    [fractionalInvestmentDurationText]: true,
         // });

         const docRef = firebase
            .firestore()
            .collection(fractionalInvestmentDurationCollection)
            .doc();

         batch.set(docRef, { ...payload, id: docRef.id });
         await batch.commit();

         setAddUnitLoading(false);
         setShowAddUnitForm(false);
         swal('Success', 'Unit added successfully', 'success');
         fetchPropertyFractionalInvestments();
      } catch (error) {
         setAddUnitLoading(false);
         console.log('error', error);
         swal('Error', 'Something went wrong', 'error');
      }
   };

   useEffect(() => {
      if (watch('fractionalUnitPlanPercentage')) {
         setValue(
            'availableUnits',
            100 / watch('fractionalUnitPlanPercentage')
         );
      }
   }, [watch, setValue]);

   useEffect(() => {
      fetchPropertyFractionalInvestments();
   }, []);

   return (
      <TabPane tabId="2" className={activeTab === 2 ? 'active' : null}>
         <div
            class="container"
            style={{
               border: '0px solid red',
               minHeight: 400,
               paddingTop: 40,
            }}
         >
            {fetchFhiLoading && (
               <Row className="justify-content-center">
                  <Spinner color="primary" />
               </Row>
            )}
            {fetchError && (
               <Alert color="danger">
                  Something went wrong. Please try again later
               </Alert>
            )}
            {fhiData.length > 0 && (
               <Row>
                  <Col lg="12">
                     <div className="pl-lg-4 px-10 mb-5">
                        {fhiData.map((item) => (
                           <FIUnit key={item.id} data={item} />
                        ))}
                     </div>
                  </Col>
               </Row>
            )}
            {!fetchFhiLoading && !fetchError && (
               <>
                  {!showAddUnitForm && (
                     <Row>
                        <div className="pl-lg-4 px-10 mb-5">
                           <Button onClick={() => setShowAddUnitForm(true)}>
                              Add Unit
                           </Button>
                        </div>
                     </Row>
                  )}
                  <Row>
                     <Col lg="12">
                        <div className="pl-lg-4 px-10">
                           <Form>
                              <UncontrolledAlert
                                 id="validate_form_msg"
                                 className=" alert-warning"
                                 style={{
                                    display: 'none',
                                    marginTop: 15,
                                 }}
                                 fade={false}
                              >
                                 <span className="alert-inner--icon">
                                    <i className="ni ni-like-2" />
                                 </span>{' '}
                                 <span className="alert-inner--text">
                                    <strong>Validation!</strong> Some important
                                    fields are not filled out properly
                                 </span>
                              </UncontrolledAlert>
                              {showAddUnitForm && (
                                 <>
                                    <Row>
                                       <Col lg="6">
                                          <FormGroup>
                                             <label className="form-control-label">
                                                Unit Name
                                             </label>
                                             <input
                                                ref={register({
                                                   required: true,
                                                })}
                                                className={`form-control-alternative form-control ${
                                                   errors.unitName
                                                      ? 'is-invalid'
                                                      : ''
                                                }`}
                                                id="unitName"
                                                name="unitName"
                                                defaultValue={''}
                                                placeholder="Enter unit name"
                                             />
                                          </FormGroup>
                                       </Col>
                                       <Col lg="6">
                                          <FormGroup id="yearsD">
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-first-name"
                                             >
                                                How many years
                                             </label>

                                             <select
                                                ref={register({
                                                   required: true,
                                                })}
                                                name="noOfYears"
                                                className={`form-control-alternative form-control ${
                                                   errors.noOfYears
                                                      ? 'is-invalid'
                                                      : ''
                                                }`}
                                                id="years"
                                             >
                                                <option value="">
                                                   -- Select number of years --
                                                </option>
                                                <option value={4}>
                                                   4 Years
                                                </option>
                                                {/* <option value={5}>
                                                   5 Years
                                                </option> */}
                                             </select>
                                          </FormGroup>
                                       </Col>
                                       <Col lg="6">
                                          <FormGroup>
                                             <label className="form-control-label">
                                                Fractional Unit Plan
                                             </label>
                                             <select
                                                ref={register({
                                                   required: true,
                                                })}
                                                name="fractionalUnitPlanPercentage"
                                                className={`form-control-alternative form-control ${
                                                   errors.fractionalUnitPlanPercentage
                                                      ? 'is-invalid'
                                                      : ''
                                                }`}
                                                id="fractionalUnitPlan"
                                             >
                                                <option value="">
                                                   -- Select fractional unit
                                                   plan --
                                                </option>
                                                <option value={1}>1%</option>
                                                <option value={10}>10%</option>
                                             </select>
                                          </FormGroup>
                                       </Col>
                                       <Col lg="6">
                                          <FormGroup>
                                             <label className="form-control-label">
                                                Available Units
                                             </label>
                                             <input
                                                ref={register({
                                                   required: true,
                                                })}
                                                className={`form-control-alternative form-control ${
                                                   errors.availableUnits
                                                      ? 'is-invalid'
                                                      : ''
                                                }`}
                                                id="availableUnits"
                                                name="availableUnits"
                                                defaultValue={''}
                                                placeholder="Enter available units"
                                                type="number"
                                             />
                                          </FormGroup>
                                       </Col>
                                       <Col lg="6">
                                          <FormGroup>
                                             <label className="form-control-label">
                                                Annual Yield Percentage
                                             </label>
                                             <input
                                                ref={register({
                                                   required: true,
                                                })}
                                                className={`form-control-alternative form-control ${
                                                   errors.annualYieldPercentage
                                                      ? 'is-invalid'
                                                      : ''
                                                }`}
                                                name="annualYieldPercentage"
                                                defaultValue={''}
                                                placeholder="Enter annual yield percentage"
                                                type="number"
                                             />
                                          </FormGroup>
                                       </Col>
                                       <Col lg="6">
                                          <FormGroup>
                                             <label className="form-control-label">
                                                Capital Gain Percentage
                                             </label>
                                             <input
                                                ref={register({
                                                   required: true,
                                                })}
                                                className={`form-control-alternative form-control ${
                                                   errors.capitalGainPercentage
                                                      ? 'is-invalid'
                                                      : ''
                                                }`}
                                                name="capitalGainPercentage"
                                                defaultValue={''}
                                                placeholder="Enter capital gain percentage"
                                                type="number"
                                             />
                                          </FormGroup>
                                       </Col>
                                    </Row>
                                    <Row className="mt-2 justify-content-end">
                                       <Button
                                          style={{ marginRight: 10 }}
                                          color="error"
                                          onClick={() =>
                                             setShowAddUnitForm(false)
                                          }
                                       >
                                          Cancel
                                       </Button>
                                       <Button
                                          color="success"
                                          type="submit"
                                          onClick={handleSubmit(onSubmit)}
                                       >
                                          {addUnitLoading ? (
                                             <>
                                                Updating...{' '}
                                                <i className="fa fa-spinner fa-spin"></i>
                                             </>
                                          ) : (
                                             'Submit'
                                          )}
                                       </Button>
                                    </Row>
                                 </>
                              )}
                           </Form>
                        </div>
                     </Col>
                  </Row>
               </>
            )}
         </div>
      </TabPane>
   );
}
