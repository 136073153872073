import React from 'react';

// reactstrap components
import {
   Button,
   Card,
   CardHeader,
   CardBody,
   FormGroup,
   Badge,
   Form,
   Input,
   Container,
   Row,
   Col,
   CardImg,
   CardTitle,
   Modal,
   Alert,
   InputGroup,
   InputGroupAddon,
   InputGroupText,
} from 'reactstrap';
// core components
import UserHeader from 'components/Headers/UserHeader.js';
import { connect } from 'react-redux';
import firebase from 'firebase/app';

import ContentLoader, { BulletList, Code } from 'react-content-loader';
import {
   PropertyEdit,
   PropertySingle,
   propertyActions,
   propertyProgressAction,
} from './../../store/actions/propertyActions';
import { promoActions } from './../../store/actions/promoActions';
import { agreementActions } from './../../store/actions/agreementActions';
import { couponActions } from './../../store/actions/couponActions';

import SinglePropertyFeatureList from './functions/SinglePropertyFeatureList';
import ImageCarousel from './functions/ImageCarousel';

import UploadLogo from './functions/UploadLogo';
import UploadPhoto from './functions/UploadPhoto';
import UploadPrimaryPhoto from './functions/UploadPrimaryPhoto';
import Documentation from './functions/Documentation';

//

import Compress from 'react-image-file-resizer';

import UploadFloorplan from './functions/UploadFloorplan';
import { isEmpty, isArray } from 'lodash';
import swal from 'sweetalert';
import CardFooter from 'reactstrap/lib/CardFooter';
import Datetime from 'react-datetime';
import PropertyProgressData from './../../views/examples/functions/PropertyProgressData';

class Property extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         propertyPhotos: [],
         propertyFloorPlan: [],
         loading: true,
         featureList: [],
         pplanState: [],
         ProEdit: true,
         editImage: 'photo',
         showAddFeatureList: false,
         showPropertyLogo: false,
         bs: null,
         PstartDate: null,
         endDate: null,
         showProgressModal: false,
         showDocumentationModal: false,

         startSavingLoader: false,
      };

      this.featureListLoop = this.featureListLoop.bind(this);
   }

   componentDidMount(props) {
      //console.log(this.props.PropertyReducer.propertySingle.floorPlan)
      //alert(JSON.stringify(this.props.PropertyReducer.PropertySingle.floorPlan))
      //this.props.PropertyEdit(true)

      this.props.propertyActions();
      this.props.promoActions();
      this.props.agreementActions();
      this.props.propertyProgressAction(
         this.props.PropertyReducer.propertySingle.id
      );
      this.props.couponActions();

      this.setState({
         propertyPhotos: this.props.PropertyReducer.propertySingle.photos,
         propertyFloorPlan:
            this.props.PropertyReducer.propertySingle.hasOwnProperty(
               'floorPlan'
            )
               ? this.props.PropertyReducer.propertySingle.floorPlan
               : null,
      });

      if (
         this.props.PropertyReducer.propertySingle.hasOwnProperty('featureList')
      ) {
         let featureListCount = Object.keys(
            this.props.PropertyReducer.propertySingle.featureList
         ).length;

         if (featureListCount > 0) {
            for (var i = 0; i < featureListCount; i++) {
               //alert(this.props.PropertyReducer.propertySingle.featureList[i])
               this.featureListLoop(
                  this.props.PropertyReducer.propertySingle.featureList[i]
               );
            }

            this.setState({ loading: false });
         }
      }
   }

   featureListLoop = async (data, count) => {
      //alert(data)
      var home = this;
      await firebase
         .firestore()
         .collection('featureList')
         .doc(data)
         .get()
         .then(function (snapshot) {
            if (snapshot.empty) {
               return;
            }

            var featureListData = [];
            var featureListId = [];

            featureListId.push({
               id: snapshot.id,
            });

            featureListData.push(snapshot.data());

            Object.assign(
               isArray(featureListData[0]) ? featureListData[0] : {},
               {
                  id: snapshot.id,
               }
            );

            //alert(JSON.stringify(snapshot.data()))
            if (snapshot) {
               home.setState((prevState) => ({
                  //featureList: [...prevState.featureList, snapshot.data()],
                  featureList: [...prevState.featureList, featureListData[0]],
               }));
            } else {
               alert('FEATURE IS EMPTY');
            }

            //this.setState({ featureList: [...this.state.featureList, ...snapshot.data() ] })
         })
         .catch((error) => {
            console.log('error', error);
         });
   };

   removeByIndex = (array, index) => {
      return array.filter(function (elem, _index) {
         return index != _index;
      });
   };

   removeByValue = (array, value) => {
      return array.filter(function (elem, _index) {
         return value != elem.src;
      });
   };

   removeByValueID = (array, value) => {
      return array.filter(function (elem, _index) {
         return value != elem.id;
      });
   };

   render() {
      // console.log("propertySingle =>",this.props.PropertyReducer.propertySingle);

      return (
         <>
            <UserHeader />
            {/* Page content */}
            {/* JSON.stringify(this.state.property) */}

            <Container className="mt--7" fluid>
               <Row>
                  <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
                     <Card className="card-profile shadow">
                        <Row className="justify-content-center">
                           <Col className="order-lg-2" lg="3">
                              <div className="card-profile-image">
                                 <Modal
                                    id="model_logo_edit"
                                    className="modal-dialog-centered"
                                    isOpen={this.state.showPropertyLogo}
                                    // toggle={() => this.toggleModal("exampleModal")}
                                 >
                                    <div className="modal-header">
                                       <h5 className="modal-title" id="_">
                                          EDIT PROPERTY LOGO
                                       </h5>

                                       <button
                                          aria-label="Close"
                                          className="close"
                                          data-dismiss="modal"
                                          type="button"
                                          onClick={(e) => {
                                             e.preventDefault();
                                             this.setState({
                                                showPropertyLogo: false,
                                             });
                                             // document.location.reload()
                                          }}
                                       >
                                          <span aria-hidden={true}>×</span>
                                       </button>
                                    </div>

                                    <div className="modal-body">
                                       <Row>
                                          <Col lg="12">
                                             <FormGroup id="proNameD">
                                                <UploadLogo
                                                   pid={
                                                      this.props.PropertyReducer
                                                         .propertySingle.id
                                                   }
                                                   data={
                                                      this.props.PropertyReducer.propertySingle.hasOwnProperty(
                                                         'photos'
                                                      )
                                                         ? this.props
                                                              .PropertyReducer
                                                              .propertySingle
                                                              .photos
                                                         : []
                                                   }
                                                />
                                             </FormGroup>
                                          </Col>
                                       </Row>
                                    </div>
                                 </Modal>

                                 {this.props.PropertyReducer.propertyEdit ? (
                                    <div
                                       style={{
                                          padding: 90,
                                          backgroundColor: 'rgb(4 4 4 / 55%)',
                                          position: 'absolute',
                                          right: '-40px',
                                          top: '-40px',
                                          zIndex: '10',
                                          borderRadius: '20px',
                                       }}
                                    >
                                       <Badge
                                          color="danger"
                                          href="#stow"
                                          title="Edit Logo"
                                          style={{
                                             fontSize: 12,
                                             position: 'absolute',
                                             //right: 20,
                                             //top: 15,
                                             zIndex: 99,
                                          }}
                                          onClick={() => {
                                             this.setState({
                                                showPropertyLogo: true,
                                             });
                                          }}
                                       >
                                          <i className="fa fa-edit"></i>
                                       </Badge>
                                    </div>
                                 ) : null}

                                 <a
                                    href="#stow"
                                    onClick={(e) => e.preventDefault()}
                                 >
                                    <img
                                       alt="..."
                                       className="rounded-circle_"
                                       /* src={require("assets/img/theme/team-4-800x800.jpg")} */
                                       src={
                                          this.props.PropertyReducer
                                             .propertySingle.logo.src
                                       }
                                    />
                                 </a>
                              </div>
                           </Col>
                        </Row>
                        <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                           <div
                              className="d-flex justify-content-between"
                              style={{ height: 80 }}
                           >
                              <Button
                                 className="mr-4"
                                 style={{ display: 'none' }}
                                 color="info"
                                 href="#stow"
                                 onClick={(e) => e.preventDefault()}
                                 size="sm"
                              >
                                 Floor Plan
                              </Button>
                              <Button
                                 className="float-right"
                                 style={{ display: 'none' }}
                                 color="default"
                                 href="#stow"
                                 onClick={(e) => {
                                    e.preventDefault();

                                    // alert('ewooo')
                                    //this.props.PropertyEdit(false)
                                 }}
                                 size="sm"
                              >
                                 Photos
                              </Button>
                           </div>
                        </CardHeader>
                        <CardBody className="pt-0 pt-md-4">
                           <Row>
                              <div className="col">
                                 <div className="card-profile-stats d-flex justify-content-center mt-md-0">
                                    <div>
                                       <span className="heading">
                                          {isEmpty(
                                             this.props.PropertyReducer
                                                .propertySingle.beds
                                          )
                                             ? 'NONE'
                                             : this.props.PropertyReducer
                                                  .propertySingle.beds}
                                       </span>
                                       <span className="description">
                                          Total Beds
                                       </span>
                                    </div>
                                    <div>
                                       <span className="heading">
                                          {this.props.PropertyReducer
                                             .propertySingle.totalUnits == ''
                                             ? 'NONE'
                                             : this.props.PropertyReducer
                                                  .propertySingle.totalUnits}
                                       </span>
                                       <span className="description">
                                          Total Unit
                                       </span>
                                    </div>
                                    <div>
                                       <span className="heading">
                                          {this.props.PropertyReducer
                                             .propertySingle.availableUnits ==
                                          ''
                                             ? 'NONE'
                                             : this.props.PropertyReducer
                                                  .propertySingle
                                                  .availableUnits}
                                       </span>
                                       <span className="description">
                                          Available
                                       </span>
                                    </div>
                                 </div>
                              </div>
                           </Row>
                           <hr className="my-4" />
                           <Row>
                              <div className="col">
                                 <Button
                                    className="btn-block btn-sm"
                                    style={{
                                       marginLeft: 0,
                                       display: this.props.PropertyReducer
                                          .propertyEdit
                                          ? 'none'
                                          : '',
                                    }}
                                    color="info"
                                    href="#stow"
                                    onClick={(e) => {
                                       e.preventDefault();
                                       this.setState({
                                          showProgressModal: true,
                                       });

                                       //this.props.PropertyEdit(false);
                                    }}
                                 >
                                    Property Progress{' '}
                                    <Badge
                                       className="badge-info"
                                       style={{
                                          color: '#fff',
                                          fontSize: 11,
                                          border: '1px solid white',
                                       }}
                                    >
                                       {' '}
                                       {this.props.PropertyReducer.hasOwnProperty(
                                          'propertyProgress'
                                       )
                                          ? Object.keys(
                                               this.props.PropertyReducer
                                                  .propertyProgress
                                            ).length
                                          : 0}
                                    </Badge>
                                 </Button>
                              </div>
                              <div className="col">
                                 <Button
                                    className="btn-block btn-sm"
                                    style={{
                                       marginLeft: 0,
                                       // padding: 7,
                                       display: this.props.PropertyReducer
                                          .propertyEdit
                                          ? 'none'
                                          : '',
                                    }}
                                    color="info"
                                    href="#stow"
                                    onClick={(e) => {
                                       e.preventDefault();
                                       this.setState({
                                          showDocumentationModal: true,
                                       });

                                       //this.props.PropertyEdit(false);
                                    }}
                                 >
                                    Documentation
                                    {this.props.PropertyReducer.propertySingle.hasOwnProperty(
                                       'titleDocument'
                                    ) &&
                                    this.props.PropertyReducer.propertySingle
                                       .titleDocument !== '' ? (
                                       <Badge
                                          className="badge-info"
                                          style={{
                                             color: '#fff',
                                             fontSize: 11,
                                             marginLeft: 10,
                                             border: '1px solid white',
                                          }}
                                       >
                                          <i class="ni ni-check-bold"></i>
                                       </Badge>
                                    ) : (
                                       <Badge
                                          className="badge-info"
                                          style={{
                                             color: 'red',
                                             fontSize: 11,
                                             marginLeft: 10,
                                             border: '1px solid white',
                                          }}
                                       >
                                          <i class="ni ni-fat-remove"></i>
                                       </Badge>
                                    )}
                                 </Button>
                              </div>
                           </Row>

                           <Modal
                              id="model_owner"
                              className="modal-dialog-centered"
                              //style={{ width: 40 + '%' }}
                              size="sm"
                              isOpen={this.state.showDocumentationModal}
                              // toggle={() => this.toggleModal("exampleModal")}
                           >
                              <div className="modal-header">
                                 <h5
                                    className="modal-title"
                                    id="exampleModalLabel"
                                 >
                                    PROPERTY DOCUMENTATION
                                 </h5>
                                 <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={(e) => {
                                       e.preventDefault();
                                       this.setState({
                                          showDocumentationModal: false,
                                       });
                                    }}
                                 >
                                    <span aria-hidden={true}>×</span>
                                 </button>
                              </div>
                              <div className="modal-body">
                                 <Documentation />
                              </div>
                           </Modal>

                           <Modal
                              id="model_owner"
                              className="modal-dialog-centered"
                              //style={{ width: 70 + '%' }}
                              size="lg"
                              isOpen={this.state.showProgressModal}
                              // toggle={() => this.toggleModal("exampleModal")}
                           >
                              <div className="modal-header">
                                 <h5
                                    className="modal-title"
                                    id="exampleModalLabel"
                                 >
                                    PROPERTY PROGRESS
                                 </h5>
                                 <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={(e) => {
                                       e.preventDefault();
                                       this.setState({
                                          showProgressModal: false,
                                       });
                                    }}
                                 >
                                    <span aria-hidden={true}>×</span>
                                 </button>
                              </div>
                              <div className="modal-body">
                                 {<PropertyProgressData />}
                              </div>
                           </Modal>
                           <Row>
                              <div className="col">
                                 <div
                                    className="edit_form"
                                    style={{
                                       display: this.props.PropertyReducer
                                          .propertyEdit
                                          ? 'block'
                                          : 'none',
                                       width: 100 + '%',
                                    }}
                                 >
                                    <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                       <div className="d-flex justify-content-between">
                                          <Button
                                             style={{
                                                padding: 1,
                                                paddingTop: 4,
                                                paddingLeft: 4,
                                                paddingRight: 4,
                                                fontSize: 11,
                                             }}
                                             className="mr-4 float-left"
                                             color="default"
                                             id="edit_primary_photo_bt"
                                             href="#stow"
                                             // className="mr-4 float-right"
                                             size="sm"
                                             onClick={(e) => {
                                                e.preventDefault();

                                                document.getElementById(
                                                   'photo_data'
                                                ).style.display = 'none';

                                                document.getElementById(
                                                   'primary_photo_data'
                                                ).style.display = 'flex';

                                                document.getElementById(
                                                   'photo_data_title'
                                                ).style.display = 'none';

                                                document.getElementById(
                                                   'floor_data'
                                                ).style.display = 'none';

                                                document.getElementById(
                                                   'floor_data_title'
                                                ).style.display = 'none';

                                                document.getElementById(
                                                   'primary_photo_data_title'
                                                ).style.display = 'block';

                                                document
                                                   .getElementById(
                                                      'edit_floor_plan_bt'
                                                   )
                                                   .classList.remove(
                                                      'btn-info'
                                                   );
                                                document
                                                   .getElementById(
                                                      'edit_floor_plan_bt'
                                                   )
                                                   .classList.add(
                                                      'btn-default'
                                                   );

                                                document
                                                   .getElementById(
                                                      'edit_photo_bt'
                                                   )
                                                   .classList.remove(
                                                      'btn-info'
                                                   );

                                                document
                                                   .getElementById(
                                                      'edit_photo_bt'
                                                   )
                                                   .classList.add(
                                                      'btn-default'
                                                   );

                                                document
                                                   .getElementById(
                                                      'edit_primary_photo_bt'
                                                   )
                                                   .classList.add('btn-info');

                                                document
                                                   .getElementById(
                                                      'edit_primary_photo_bt'
                                                   )
                                                   .classList.remove(
                                                      'btn-default'
                                                   );

                                                this.setState({
                                                   editImage: 'primaryPhoto',
                                                });
                                             }}
                                          >
                                             Edit Primary Photo
                                          </Button>

                                          <Button
                                             className="float-right mr-3"
                                             color="info"
                                             href="#stow"
                                             id="edit_floor_plan_bt"
                                             onClick={(e) => {
                                                e.preventDefault();

                                                document.getElementById(
                                                   'photo_data'
                                                ).style.display = 'flex';

                                                document.getElementById(
                                                   'photo_data_title'
                                                ).style.display = 'block';

                                                document.getElementById(
                                                   'primary_photo_data_title'
                                                ).style.display = 'none';

                                                document.getElementById(
                                                   'primary_photo_data'
                                                ).style.display = 'none';

                                                document.getElementById(
                                                   'floor_data'
                                                ).style.display = 'none';

                                                document.getElementById(
                                                   'floor_data_title'
                                                ).style.display = 'none';

                                                document
                                                   .getElementById(
                                                      'edit_floor_plan_bt'
                                                   )
                                                   .classList.add('btn-info');
                                                document
                                                   .getElementById(
                                                      'edit_floor_plan_bt'
                                                   )
                                                   .classList.remove(
                                                      'btn-default'
                                                   );

                                                document
                                                   .getElementById(
                                                      'edit_photo_bt'
                                                   )
                                                   .classList.remove(
                                                      'btn-info'
                                                   );
                                                document
                                                   .getElementById(
                                                      'edit_photo_bt'
                                                   )
                                                   .classList.add(
                                                      'btn-default'
                                                   );

                                                document
                                                   .getElementById(
                                                      'edit_primary_photo_bt'
                                                   )
                                                   .classList.remove(
                                                      'btn-info'
                                                   );

                                                document
                                                   .getElementById(
                                                      'edit_primary_photo_bt'
                                                   )
                                                   .classList.add(
                                                      'btn-default'
                                                   );

                                                this.setState({
                                                   editImage: 'photo',
                                                });

                                                // alert('ewooo')
                                                //this.props.PropertyEdit(false)
                                             }}
                                             size="sm"
                                          >
                                             Edit Gallery
                                          </Button>

                                          <Button
                                             className="mr-3 float-right"
                                             color="default"
                                             id="edit_photo_bt"
                                             href="#stow"
                                             onClick={(e) => {
                                                e.preventDefault();

                                                document.getElementById(
                                                   'photo_data'
                                                ).style.display = 'none';

                                                document.getElementById(
                                                   'primary_photo_data'
                                                ).style.display = 'none';

                                                document.getElementById(
                                                   'photo_data_title'
                                                ).style.display = 'none';

                                                document.getElementById(
                                                   'floor_data'
                                                ).style.display = 'flex';

                                                document.getElementById(
                                                   'floor_data_title'
                                                ).style.display = 'block';

                                                document.getElementById(
                                                   'primary_photo_data_title'
                                                ).style.display = 'none';

                                                document
                                                   .getElementById(
                                                      'edit_floor_plan_bt'
                                                   )
                                                   .classList.remove(
                                                      'btn-info'
                                                   );
                                                document
                                                   .getElementById(
                                                      'edit_floor_plan_bt'
                                                   )
                                                   .classList.add(
                                                      'btn-default'
                                                   );

                                                document
                                                   .getElementById(
                                                      'edit_photo_bt'
                                                   )
                                                   .classList.add('btn-info');
                                                document
                                                   .getElementById(
                                                      'edit_photo_bt'
                                                   )
                                                   .classList.remove(
                                                      'btn-default'
                                                   );

                                                document
                                                   .getElementById(
                                                      'edit_primary_photo_bt'
                                                   )
                                                   .classList.remove(
                                                      'btn-info'
                                                   );

                                                document
                                                   .getElementById(
                                                      'edit_primary_photo_bt'
                                                   )
                                                   .classList.add(
                                                      'btn-default'
                                                   );

                                                this.setState({
                                                   editImage: 'floorPlan',
                                                });
                                             }}
                                             size="sm"
                                          >
                                             Edit Floor Plan
                                          </Button>
                                       </div>
                                    </CardHeader>

                                    <h6
                                       className="heading-small text-muted mb-4"
                                       id="photo_data_title"
                                    >
                                       <center>
                                          <Alert
                                             color=""
                                             style={{
                                                backgroundColor: '#ecf0f3',
                                             }}
                                          >
                                             <strong>EDIT GALLERY!</strong>{' '}
                                             <div
                                                style={{
                                                   textTransform: 'none',
                                                   fontSize: 10,
                                                }}
                                             >
                                                Manage Property Gallery
                                             </div>
                                          </Alert>
                                       </center>
                                    </h6>

                                    <h6
                                       className="heading-small text-muted mb-4"
                                       id="floor_data_title"
                                       style={{ display: 'none' }}
                                    >
                                       <center>
                                          <Alert
                                             color=""
                                             style={{
                                                backgroundColor: '#ecf0f3',
                                             }}
                                          >
                                             <strong>EDIT FLOOR PLAN!</strong>{' '}
                                             <div
                                                style={{
                                                   textTransform: 'none',
                                                   fontSize: 10,
                                                }}
                                             >
                                                Manage Property Floor Plan
                                             </div>
                                          </Alert>
                                       </center>
                                    </h6>

                                    <h6
                                       className="heading-small text-muted mb-4"
                                       id="primary_photo_data_title"
                                       style={{ display: 'none' }}
                                    >
                                       <center>
                                          <Alert
                                             color=""
                                             style={{
                                                backgroundColor: '#ecf0f3',
                                             }}
                                          >
                                             <strong>
                                                EDIT PRIMARY PHOTO!
                                             </strong>{' '}
                                             <div
                                                style={{
                                                   textTransform: 'none',
                                                   fontSize: 10,
                                                }}
                                             >
                                                Manage Property Primary Photo
                                             </div>
                                          </Alert>
                                       </center>
                                    </h6>

                                    <Row
                                       id="primary_photo_data"
                                       style={{ display: 'none' }}
                                    >
                                       <Col sm="12">
                                          <Card
                                             style={{
                                                width: 'auto',
                                                marginTop: 5,
                                                paddingTop: 10,
                                             }}
                                          >
                                             <CardImg
                                                className="text-center"
                                                style={{
                                                   height: 200,
                                                   paddingLeft: 10,
                                                   paddingRight: 10,
                                                }}
                                                src={
                                                   this.props.PropertyReducer.propertySingle.hasOwnProperty(
                                                      'primaryPhoto'
                                                   )
                                                      ? this.props
                                                           .PropertyReducer
                                                           .propertySingle
                                                           .primaryPhoto.src
                                                      : null
                                                }
                                                top
                                             />
                                             <CardBody>
                                                <CardTitle
                                                   style={{
                                                      fontSize: 10,
                                                      paddingBottom: 0,
                                                   }}
                                                >
                                                   {this.props.PropertyReducer.propertySingle.hasOwnProperty(
                                                      'primaryPhoto'
                                                   )
                                                      ? this.props
                                                           .PropertyReducer
                                                           .propertySingle
                                                           .primaryPhoto
                                                           .primaryPhoto
                                                      : null}
                                                </CardTitle>
                                             </CardBody>
                                          </Card>
                                       </Col>
                                    </Row>

                                    <Row id="photo_data">
                                       {this.props.PropertyReducer.propertySingle.hasOwnProperty(
                                          'photos'
                                       )
                                          ? this.props.PropertyReducer.propertySingle.photos.map(
                                               (data, i) => {
                                                  return (
                                                     <Col
                                                        sm="6"
                                                        id={'photo_' + i}
                                                     >
                                                        <Card
                                                           style={{
                                                              width: 'auto',
                                                              marginTop: 5,
                                                              paddingTop: 10,
                                                           }}
                                                        >
                                                           <CardImg
                                                              className="text-center"
                                                              style={{
                                                                 height: 85,
                                                                 paddingLeft: 10,
                                                                 paddingRight: 10,
                                                              }}
                                                              src={data.src}
                                                              top
                                                           />
                                                           <CardBody>
                                                              <CardTitle
                                                                 style={{
                                                                    fontSize: 10,
                                                                    paddingBottom: 0,
                                                                 }}
                                                              >
                                                                 {data.photos}
                                                              </CardTitle>
                                                              <Button
                                                                 block
                                                                 color="danger"
                                                                 href="#stow"
                                                                 onClick={(
                                                                    e
                                                                 ) => {
                                                                    e.preventDefault();

                                                                    swal({
                                                                       title: 'Delete Photo!',
                                                                       // html:true,
                                                                       text:
                                                                          'Are you sure you want to delete ' +
                                                                          data.photos +
                                                                          ' ?',
                                                                       icon: 'warning',
                                                                       buttons: true,
                                                                       buttons:
                                                                          {
                                                                             cancel:
                                                                                'Cancel',
                                                                             catch: {
                                                                                text: 'Delete',
                                                                                //value: "catch",
                                                                             },
                                                                             //defeat: true,
                                                                          },
                                                                       dangerMode: true,
                                                                    }).then(
                                                                       (
                                                                          willDelete
                                                                       ) => {
                                                                          if (
                                                                             willDelete
                                                                          ) {
                                                                             let arr =
                                                                                this
                                                                                   .state
                                                                                   .propertyPhotos;

                                                                             document.getElementById(
                                                                                'photo_msg_' +
                                                                                   i
                                                                             ).html =
                                                                                '<div style="font-size:9px">Deleting Photo... <i class="fa fa-spinner fa-spin"></i></div>';

                                                                             this.setState(
                                                                                {
                                                                                   propertyPhotos:
                                                                                      this.removeByValue(
                                                                                         this
                                                                                            .state
                                                                                            .propertyPhotos,
                                                                                         data.src
                                                                                      ),
                                                                                },
                                                                                async () => {
                                                                                   // console.log(this.state.propertyPhotos)
                                                                                   //console.log(this.props.PropertyReducer.propertySingle.id)

                                                                                   const photo =
                                                                                      {
                                                                                         photos:
                                                                                            this
                                                                                               .state
                                                                                               .propertyPhotos,
                                                                                      };

                                                                                   await firebase
                                                                                      .firestore()
                                                                                      .collection(
                                                                                         'property'
                                                                                      )
                                                                                      .doc(
                                                                                         this
                                                                                            .props
                                                                                            .PropertyReducer
                                                                                            .propertySingle
                                                                                            .id
                                                                                      )
                                                                                      .update(
                                                                                         photo
                                                                                      )
                                                                                      .then(
                                                                                         async () => {
                                                                                            const Property =
                                                                                               await firebase
                                                                                                  .firestore()
                                                                                                  .collection(
                                                                                                     'property'
                                                                                                  )
                                                                                                  .doc(
                                                                                                     this
                                                                                                        .props
                                                                                                        .PropertyReducer
                                                                                                        .propertySingle
                                                                                                        .id
                                                                                                  )
                                                                                                  .get();

                                                                                            // document.getElementById('photo_msg_'+i).innerHTML=

                                                                                            document.getElementById(
                                                                                               'photo_msg_' +
                                                                                                  i
                                                                                            ).html =
                                                                                               '<div style="font-size:9px">Updating Deleted Photo... <i className="fa fa-spinner fa-spin"></i></div>';

                                                                                            var propertyData =
                                                                                               [];
                                                                                            var projectId =
                                                                                               [];

                                                                                            projectId.push(
                                                                                               {
                                                                                                  id: Property.id,
                                                                                               }
                                                                                            );

                                                                                            propertyData.push(
                                                                                               Property.data()
                                                                                            );

                                                                                            Object.assign(
                                                                                               propertyData[0],
                                                                                               {
                                                                                                  id: Property.id,
                                                                                               }
                                                                                            );

                                                                                            //console.log(propertyData[0])

                                                                                            document.getElementById(
                                                                                               'photo_' +
                                                                                                  i
                                                                                            ).style.display =
                                                                                               'none';

                                                                                            this.props.PropertySingle(
                                                                                               propertyData[0]
                                                                                            );

                                                                                            swal(
                                                                                               'Deleted',
                                                                                               'You have successfully Deleted ' +
                                                                                                  data.photos,
                                                                                               {
                                                                                                  icon: 'success',
                                                                                               }
                                                                                            ).then(
                                                                                               (
                                                                                                  value
                                                                                               ) => {
                                                                                                  document.location.reload();
                                                                                               }
                                                                                            );
                                                                                         }
                                                                                      );
                                                                                }
                                                                             );
                                                                          } else {
                                                                             //swal("Your imaginary file is safe!");
                                                                          }
                                                                       }
                                                                    );
                                                                 }}
                                                              >
                                                                 Delete
                                                              </Button>
                                                           </CardBody>
                                                           <CardFooter
                                                              id={
                                                                 'photo_msg_' +
                                                                 i
                                                              }
                                                              style={{
                                                                 fontSize: 9,
                                                              }}
                                                           ></CardFooter>
                                                        </Card>
                                                     </Col>
                                                  );
                                               }
                                            )
                                          : ''}
                                    </Row>

                                    <Row
                                       id="floor_data"
                                       style={{ display: 'none' }}
                                    >
                                       {this.props.PropertyReducer.propertySingle.hasOwnProperty(
                                          'floorPlan'
                                       )
                                          ? this.props.PropertyReducer.propertySingle.floorPlan.map(
                                               (data, i) => {
                                                  return (
                                                     <Col
                                                        sm="6"
                                                        id={'floor_' + i}
                                                     >
                                                        <Card
                                                           style={{
                                                              width: 'auto',
                                                              marginTop: 5,
                                                              paddingTop: 10,
                                                           }}
                                                        >
                                                           <CardImg
                                                              className="text-center"
                                                              style={{
                                                                 height: 85,
                                                                 paddingLeft: 10,
                                                                 paddingRight: 10,
                                                              }}
                                                              src={data.src}
                                                              top
                                                           />
                                                           <CardBody>
                                                              <CardTitle
                                                                 style={{
                                                                    fontSize: 10,
                                                                    paddingBottom: 0,
                                                                 }}
                                                              >
                                                                 {
                                                                    data.floorPlan
                                                                 }
                                                              </CardTitle>
                                                              <Button
                                                                 block
                                                                 color="danger"
                                                                 href="#stow"
                                                                 onClick={(
                                                                    e
                                                                 ) => {
                                                                    e.preventDefault();

                                                                    swal({
                                                                       title: 'Delete Floor Plan!',
                                                                       // html:true,
                                                                       text:
                                                                          'Are you sure you want to delete ' +
                                                                          data.floorPlan +
                                                                          ' ?',
                                                                       icon: 'warning',
                                                                       buttons: true,
                                                                       buttons:
                                                                          {
                                                                             cancel:
                                                                                'Cancel',
                                                                             catch: {
                                                                                text: 'Delete',
                                                                                //value: "catch",
                                                                             },
                                                                             //defeat: true,
                                                                          },
                                                                       dangerMode: true,
                                                                    }).then(
                                                                       (
                                                                          willDelete
                                                                       ) => {
                                                                          if (
                                                                             willDelete
                                                                          ) {
                                                                             let arr =
                                                                                this
                                                                                   .state
                                                                                   .propertyFloorPlan;

                                                                             document
                                                                                .getElementById(
                                                                                   'floor_msg_' +
                                                                                      i
                                                                                )
                                                                                .insertAdjacentHTML(
                                                                                   'afterend',
                                                                                   '<div style="font-size:9px">Deleting Floor Plan... <i class="fa fa-spinner fa-spin"></i></div>'
                                                                                );

                                                                             this.setState(
                                                                                {
                                                                                   propertyFloorPlan:
                                                                                      this.removeByValue(
                                                                                         this
                                                                                            .state
                                                                                            .propertyFloorPlan,
                                                                                         data.src
                                                                                      ),
                                                                                },
                                                                                async () => {
                                                                                   // console.log(this.state.propertyPhotos)
                                                                                   //console.log(this.props.PropertyReducer.propertySingle.id)

                                                                                   const floor =
                                                                                      {
                                                                                         floorPlan:
                                                                                            this
                                                                                               .state
                                                                                               .propertyFloorPlan,
                                                                                      };

                                                                                   await firebase
                                                                                      .firestore()
                                                                                      .collection(
                                                                                         'property'
                                                                                      )
                                                                                      .doc(
                                                                                         this
                                                                                            .props
                                                                                            .PropertyReducer
                                                                                            .propertySingle
                                                                                            .id
                                                                                      )
                                                                                      .update(
                                                                                         floor
                                                                                      )
                                                                                      .then(
                                                                                         async () => {
                                                                                            const Property =
                                                                                               await firebase
                                                                                                  .firestore()
                                                                                                  .collection(
                                                                                                     'property'
                                                                                                  )
                                                                                                  .doc(
                                                                                                     this
                                                                                                        .props
                                                                                                        .PropertyReducer
                                                                                                        .propertySingle
                                                                                                        .id
                                                                                                  )
                                                                                                  .get();

                                                                                            // document.getElementById('photo_msg_'+i).innerHTML=

                                                                                            document
                                                                                               .getElementById(
                                                                                                  'floor_msg_' +
                                                                                                     i
                                                                                               )
                                                                                               .insertAdjacentHTML(
                                                                                                  'afterend',
                                                                                                  '<div style="font-size:9px">Updating Deleted FloorPlan... <i className="fa fa-spinner fa-spin"></i></div>'
                                                                                               );

                                                                                            var propertyData =
                                                                                               [];
                                                                                            var projectId =
                                                                                               [];

                                                                                            projectId.push(
                                                                                               {
                                                                                                  id: Property.id,
                                                                                               }
                                                                                            );

                                                                                            propertyData.push(
                                                                                               Property.data()
                                                                                            );

                                                                                            Object.assign(
                                                                                               propertyData[0],
                                                                                               {
                                                                                                  id: Property.id,
                                                                                               }
                                                                                            );

                                                                                            //console.log(propertyData[0])

                                                                                            document.getElementById(
                                                                                               'floor_' +
                                                                                                  i
                                                                                            ).style.display =
                                                                                               'none';

                                                                                            this.props.PropertySingle(
                                                                                               propertyData[0]
                                                                                            );

                                                                                            swal(
                                                                                               'Deleted',
                                                                                               'You have successfully Deleted ' +
                                                                                                  data.floorPlan,
                                                                                               {
                                                                                                  icon: 'success',
                                                                                               }
                                                                                            ).then(
                                                                                               (
                                                                                                  value
                                                                                               ) => {
                                                                                                  document.location.reload();
                                                                                               }
                                                                                            );
                                                                                         }
                                                                                      );
                                                                                }
                                                                             );
                                                                          } else {
                                                                             //swal("Your imaginary file is safe!");
                                                                          }
                                                                       }
                                                                    );
                                                                 }}
                                                              >
                                                                 Delete
                                                              </Button>
                                                           </CardBody>
                                                           <CardFooter
                                                              id={
                                                                 'floor_msg_' +
                                                                 i
                                                              }
                                                              style={{
                                                                 fontSize: 9,
                                                              }}
                                                           ></CardFooter>
                                                        </Card>
                                                     </Col>
                                                  );
                                               }
                                            )
                                          : ''}
                                    </Row>

                                    <Row>
                                       <Col>
                                          {this.state.editImage == 'photo' && (
                                             <UploadPhoto
                                                pid={
                                                   this.props.PropertyReducer
                                                      .propertySingle.id
                                                }
                                                data={
                                                   this.props.PropertyReducer.propertySingle.hasOwnProperty(
                                                      'photos'
                                                   )
                                                      ? this.props
                                                           .PropertyReducer
                                                           .propertySingle
                                                           .photos
                                                      : []
                                                }
                                             />
                                          )}

                                          {this.state.editImage ==
                                             'primaryPhoto' && (
                                             <UploadPrimaryPhoto
                                                style={{ marginTop: 20 }}
                                                pid={
                                                   this.props.PropertyReducer
                                                      .propertySingle.id
                                                }
                                                data={
                                                   this.props.PropertyReducer.propertySingle.hasOwnProperty(
                                                      'photos'
                                                   )
                                                      ? this.props
                                                           .PropertyReducer
                                                           .propertySingle
                                                           .photos
                                                      : []
                                                }
                                             />
                                          )}

                                          {this.state.editImage ==
                                             'floorPlan' && (
                                             <UploadFloorplan
                                                pid={
                                                   this.props.PropertyReducer
                                                      .propertySingle.id
                                                }
                                                data={
                                                   this.props.PropertyReducer.propertySingle.hasOwnProperty(
                                                      'floorPlan'
                                                   )
                                                      ? this.props
                                                           .PropertyReducer
                                                           .propertySingle
                                                           .floorPlan
                                                      : []
                                                }
                                             />
                                          )}
                                       </Col>
                                    </Row>
                                 </div>
                              </div>
                           </Row>
                           <div
                              className="text-center"
                              style={{ display: 'none' }}
                           >
                              <h3>
                                 Jessica Jones
                                 <span className="font-weight-light">, 27</span>
                              </h3>
                              <div className="h5 font-weight-300">
                                 <i className="ni location_pin mr-2" />
                                 Bucharest, Romania
                              </div>
                              <div className="h5 mt-4">
                                 <i className="ni business_briefcase-24 mr-2" />
                                 Solution Manager - Creative Tim Officer
                              </div>
                              <div>
                                 <i className="ni education_hat mr-2" />
                                 University of Computer Science
                              </div>
                              <hr className="my-4" />
                              <p>
                                 Ryan — the name taken by Melbourne-raised,
                                 Brooklyn-based Nick Murphy — writes, performs
                                 and records all of his own music.
                              </p>
                              <a
                                 href="#stow"
                                 onClick={(e) => e.preventDefault()}
                              >
                                 Show more
                              </a>
                           </div>
                        </CardBody>
                     </Card>
                  </Col>
                  <Col className="order-xl-1" xl="8">
                     <div
                        className="edit_form"
                        style={{
                           display: this.props.PropertyReducer.propertyEdit
                              ? 'block'
                              : 'none',
                           width: 100 + '%',
                        }}
                     >
                        <Card className="bg-secondary shadow mb-2">
                           <CardHeader className="bg-white border-0">
                              <h6 className="heading-small text-muted mb-4">
                                 FEATURE LIST
                                 <Button
                                    color="primary"
                                    href="#"
                                    onClick={() => {
                                       this.setState({
                                          showAddFeatureList: true,
                                       });
                                    }}
                                    title="Add New Feature List"
                                    style={{
                                       fontSize: 15,
                                       paddingBottom: 0,
                                       marginLeft: 10,
                                    }}
                                    size="sm"
                                 >
                                    <i className="ni ni-fat-add"></i>
                                 </Button>
                                 <Modal
                                    id="model_payment"
                                    className="modal-dialog-centered"
                                    isOpen={this.state.showAddFeatureList}
                                    // toggle={() => this.toggleModal("exampleModal")}
                                 >
                                    <div className="modal-header">
                                       <h5
                                          className="modal-title"
                                          id="exampleModalLabel"
                                       >
                                          PROPERTY FEATURE LIST
                                       </h5>

                                       <button
                                          aria-label="Close"
                                          className="close"
                                          data-dismiss="modal"
                                          type="button"
                                          onClick={(e) => {
                                             e.preventDefault();
                                             this.setState({
                                                showAddFeatureList: false,
                                             });
                                             // document.location.reload()
                                          }}
                                       >
                                          <span aria-hidden={true}>×</span>
                                       </button>
                                    </div>

                                    <div className="modal-body">
                                       <div class="container">
                                          <div class="row">
                                             {this.props.FeaturedListReducer
                                                .FListactionsData &&
                                                this.props.FeaturedListReducer.FListactionsData.map(
                                                   (data, i) => {
                                                      return (
                                                         <div
                                                            class="col-12"
                                                            style={{
                                                               background:
                                                                  '#fff',
                                                               padding: '10px',
                                                               borderRadius:
                                                                  '5px',
                                                               boxShadow:
                                                                  '0 0 12px #e2e7ef',
                                                               marginBottom:
                                                                  '10px',
                                                               fontSize: 11,
                                                            }}
                                                         >
                                                            <div>
                                                               {/* <i className=" ni ni-active-40" /> */}
                                                               <CardImg
                                                                  className="text-center"
                                                                  style={{
                                                                     height: 25,
                                                                     width: 25,
                                                                     marginRight: 10,
                                                                  }}
                                                                  src={
                                                                     data.icon
                                                                        .src
                                                                  }
                                                                  top
                                                               />
                                                               <span
                                                                  style={{
                                                                     fontSize: 8,
                                                                  }}
                                                               >
                                                                  {data.text}
                                                               </span>
                                                            </div>

                                                            <span
                                                               id={
                                                                  'add_new_fl_' +
                                                                  data.id
                                                               }
                                                               style={{
                                                                  float: 'right',
                                                               }}
                                                            ></span>
                                                            <Button
                                                               disabled={
                                                                  this.props.PropertyReducer.propertySingle.hasOwnProperty(
                                                                     'featureList'
                                                                  )
                                                                     ? this.props.PropertyReducer.propertySingle.featureList.includes(
                                                                          data.id
                                                                       )
                                                                     : null
                                                               }
                                                               className="mr-2"
                                                               style={{
                                                                  float: 'right',
                                                                  marginTop:
                                                                     -25,
                                                                  fontSize: 9,
                                                               }}
                                                               title={
                                                                  'Add to ' +
                                                                  this.props
                                                                     .PropertyReducer
                                                                     .propertySingle
                                                                     .name
                                                               }
                                                               color="info"
                                                               href="#stow"
                                                               onClick={(e) => {
                                                                  swal({
                                                                     title: 'Add Feature List ?',
                                                                     // html:true,
                                                                     text:
                                                                        'Add ' +
                                                                        data.text +
                                                                        ' to ' +
                                                                        this
                                                                           .props
                                                                           .PropertyReducer
                                                                           .propertySingle
                                                                           .name +
                                                                        '?',
                                                                     icon: 'warning',
                                                                     buttons: true,
                                                                     buttons: {
                                                                        cancel:
                                                                           'Cancel',
                                                                        catch: {
                                                                           text: 'Add',
                                                                           //value: "catch",
                                                                        },
                                                                        //defeat: true,
                                                                     },
                                                                     dangerMode: true,
                                                                  }).then(
                                                                     (
                                                                        willDelete
                                                                     ) => {
                                                                        if (
                                                                           willDelete
                                                                        ) {
                                                                           document.getElementById(
                                                                              'add_new_fl_' +
                                                                                 data.id
                                                                           ).innerHTML =
                                                                              '<i class="fa fa-spinner fa-spin"></i>';
                                                                           e.preventDefault();

                                                                           // console.log(data)

                                                                           var Fl =
                                                                              this
                                                                                 .state
                                                                                 .featureList;
                                                                           let fListBK =
                                                                              this.props.PropertyReducer.propertySingle.hasOwnProperty(
                                                                                 'featureList'
                                                                              )
                                                                                 ? this
                                                                                      .props
                                                                                      .PropertyReducer
                                                                                      .propertySingle
                                                                                      .featureList
                                                                                 : [];
                                                                           fListBK.push(
                                                                              data.id
                                                                           );

                                                                           Fl.push(
                                                                              data
                                                                           );

                                                                           this.setState(
                                                                              {
                                                                                 featureList:
                                                                                    Fl,
                                                                              },
                                                                              async () => {
                                                                                 //console.log(Fl)
                                                                                 //console.log(fListBK)

                                                                                 const featureListUpdated =
                                                                                    {
                                                                                       featureList:
                                                                                          fListBK,
                                                                                    };

                                                                                 await firebase
                                                                                    .firestore()
                                                                                    .collection(
                                                                                       'property'
                                                                                    )
                                                                                    .doc(
                                                                                       this
                                                                                          .props
                                                                                          .PropertyReducer
                                                                                          .propertySingle
                                                                                          .id
                                                                                    )
                                                                                    .update(
                                                                                       featureListUpdated
                                                                                    )
                                                                                    .then(
                                                                                       async () => {
                                                                                          const Property =
                                                                                             await firebase
                                                                                                .firestore()
                                                                                                .collection(
                                                                                                   'property'
                                                                                                )
                                                                                                .doc(
                                                                                                   this
                                                                                                      .props
                                                                                                      .PropertyReducer
                                                                                                      .propertySingle
                                                                                                      .id
                                                                                                )
                                                                                                .get();

                                                                                          var propertyData =
                                                                                             [];

                                                                                          propertyData.push(
                                                                                             Property.data()
                                                                                          );

                                                                                          Object.assign(
                                                                                             propertyData[0],
                                                                                             {
                                                                                                id: Property.id,
                                                                                             }
                                                                                          );

                                                                                          this.props.PropertySingle(
                                                                                             propertyData[0]
                                                                                          );

                                                                                          document.getElementById(
                                                                                             'add_new_fl_' +
                                                                                                data.id
                                                                                          ).innerHTML =
                                                                                             '';

                                                                                          swal(
                                                                                             'Feature List Added',
                                                                                             'You have successfully Added the Feature List - ' +
                                                                                                data.text,
                                                                                             {
                                                                                                icon: 'success',
                                                                                             }
                                                                                          ).then(
                                                                                             (
                                                                                                value
                                                                                             ) => {
                                                                                                //document.location.reload()
                                                                                             }
                                                                                          );
                                                                                       }
                                                                                    );
                                                                              }
                                                                           );
                                                                        }
                                                                     }
                                                                  );
                                                               }}
                                                               size="sm"
                                                            >
                                                               Add
                                                            </Button>
                                                         </div>
                                                      );
                                                   }
                                                )}
                                          </div>
                                       </div>
                                    </div>
                                 </Modal>
                              </h6>
                              <hr className="my-0 mt-0" />
                           </CardHeader>
                           <CardBody
                              style={{ backgroundColor: 'white', padding: 10 }}
                           >
                              <div
                                 id="flist_edit_msg_loader"
                                 style={{ paddingLeft: 50 }}
                              ></div>
                              <Row className="">
                                 {Object.keys(this.state.featureList).length >
                                 0 ? (
                                    this.state.featureList.map((data, i) => {
                                       return (
                                          <Col lg="3" md="6">
                                             <button
                                                className=" btn-icon-clipboard delete_FeturedList"
                                                //id="tooltip982655500"
                                                type="button"
                                             >
                                                <div>
                                                   {/* <i className=" ni ni-active-40" /> */}
                                                   <CardImg
                                                      className="text-center"
                                                      style={{
                                                         height: 40,
                                                         width: 40,
                                                      }}
                                                      src={data.icon.src}
                                                      top
                                                   />
                                                   <span
                                                      style={{ fontSize: 8 }}
                                                   >
                                                      {data.text}
                                                   </span>
                                                </div>

                                                <span
                                                   id={
                                                      'flist_edit_msg_' +
                                                      data.id
                                                   }
                                                ></span>
                                             </button>

                                             <Badge
                                                color="danger"
                                                href="#stow"
                                                style={{
                                                   fontSize: 12,
                                                   position: 'absolute',
                                                   right: 20,
                                                   top: 15,
                                                }}
                                                onClick={(e) => {
                                                   e.preventDefault();

                                                   swal({
                                                      title: 'Delete Feature List Item?',
                                                      // html:true,
                                                      text:
                                                         'Are you sure you want to Delete Feature List ' +
                                                         data.text +
                                                         ' ?',
                                                      icon: 'warning',
                                                      buttons: true,
                                                      buttons: {
                                                         cancel: 'Cancel',
                                                         catch: {
                                                            text: 'Delete',
                                                            //value: "catch",
                                                         },
                                                         //defeat: true,
                                                      },
                                                      dangerMode: true,
                                                   }).then((willDelete) => {
                                                      if (willDelete) {
                                                         let fListBK =
                                                            this.props
                                                               .PropertyReducer
                                                               .propertySingle
                                                               .featureList;
                                                         let arr =
                                                            this.state
                                                               .featureList;

                                                         /* document.getElementById("flist_edit_msg_" + data.id )
                                                    .insertAdjacentHTML(
                                                      "afterend",
                                                      '<div style="font-size:9px">Deleting item... <i className="fa fa-spinner fa-spin"></i></div>'
                                                    ); */

                                                         document.getElementById(
                                                            'flist_edit_msg_loader'
                                                         ).innerHTML =
                                                            '<div style="font-size:9px">Deleting item... <i class="fa fa-spinner fa-spin"></i></div>';

                                                         //flist_edit_msg_loader

                                                         //console.log(fListBK)

                                                         //console.log(data.id)
                                                         //fListBK = this.removeByValue(fListBK,data.id)

                                                         //console.log(this.removeByIndex(fListBK,i))

                                                         this.setState(
                                                            {
                                                               featureList:
                                                                  this.removeByValueID(
                                                                     arr,
                                                                     data.id
                                                                  ),
                                                            },
                                                            async () => {
                                                               //console.log(this.state.featureList)

                                                               // console.log(this.state.propertyPhotos)
                                                               //console.log(this.props.PropertyReducer.propertySingle.id)

                                                               const featureListUpdated =
                                                                  {
                                                                     featureList:
                                                                        this.removeByIndex(
                                                                           fListBK,
                                                                           i
                                                                        ),
                                                                  };

                                                               await firebase
                                                                  .firestore()
                                                                  .collection(
                                                                     'property'
                                                                  )
                                                                  .doc(
                                                                     this.props
                                                                        .PropertyReducer
                                                                        .propertySingle
                                                                        .id
                                                                  )
                                                                  .update(
                                                                     featureListUpdated
                                                                  )
                                                                  .then(
                                                                     async () => {
                                                                        const Property =
                                                                           await firebase
                                                                              .firestore()
                                                                              .collection(
                                                                                 'property'
                                                                              )
                                                                              .doc(
                                                                                 this
                                                                                    .props
                                                                                    .PropertyReducer
                                                                                    .propertySingle
                                                                                    .id
                                                                              )
                                                                              .get();

                                                                        // document.getElementById('photo_msg_'+i).innerHTML=

                                                                        var propertyData =
                                                                           [];

                                                                        propertyData.push(
                                                                           Property.data()
                                                                        );

                                                                        Object.assign(
                                                                           propertyData[0],
                                                                           {
                                                                              id: Property.id,
                                                                           }
                                                                        );

                                                                        this.props.PropertySingle(
                                                                           propertyData[0]
                                                                        );

                                                                        document.getElementById(
                                                                           'flist_edit_msg_loader'
                                                                        ).innerHTML =
                                                                           '';

                                                                        swal(
                                                                           'Feature List Deleted',
                                                                           'You have successfully Deleted the Feature List - ' +
                                                                              data.text,
                                                                           {
                                                                              icon: 'success',
                                                                           }
                                                                        ).then(
                                                                           (
                                                                              value
                                                                           ) => {
                                                                              //document.location.reload()
                                                                           }
                                                                        );
                                                                     }
                                                                  );
                                                            }
                                                         );
                                                      }
                                                   });
                                                }}
                                             >
                                                <i className="ni ni-fat-remove"></i>
                                             </Badge>
                                          </Col>
                                       );
                                    })
                                 ) : (
                                    <Col>
                                       {' '}
                                       <Code />
                                    </Col>
                                 )}
                              </Row>
                           </CardBody>
                        </Card>
                     </div>

                     <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                           <Row className="align-items-center">
                              <Col xs="8">
                                 <h3 className="mb-0"></h3>
                              </Col>
                              <Col className="text-right" xs="4">
                                 {/*  <Button
                        color="primary"
                        href="#stow"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        Settings
                      </Button> */}
                              </Col>
                           </Row>
                        </CardHeader>
                        <CardBody>
                           <Form>
                              <div
                                 className="display_form"
                                 style={{
                                    display: this.props.PropertyReducer
                                       .propertyEdit
                                       ? 'none'
                                       : 'block',
                                    width: 100 + '%',
                                 }}
                              >
                                 <h6 className="heading-small text-muted mb-4">
                                    Featured List{' '}
                                    <Badge
                                       color="info"
                                       href="#stow"
                                       style={{ fontSize: 12 }}
                                       onClick={(e) => e.preventDefault()}
                                    >
                                       {
                                          Object.keys(this.state.featureList)
                                             .length
                                       }
                                    </Badge>
                                 </h6>
                                 <div className="pl-lg-4">
                                    <Row>
                                       {this.state.loading ? (
                                          <Col>
                                             <Code />
                                          </Col>
                                       ) : Object.keys(this.state.featureList)
                                            .length > 0 ? (
                                          this.state.featureList.map((data) => {
                                             return (
                                                <SinglePropertyFeatureList
                                                   featureList={data}
                                                />
                                             );
                                          })
                                       ) : (
                                          <Col>
                                             <Code />
                                          </Col>
                                       )}
                                    </Row>
                                 </div>

                                 <hr className="my-4 mt-5" />
                                 {/* Address */}

                                 <h6 className="heading-small text-muted mb-4 mt-5">
                                    Gallery{' '}
                                    <Badge
                                       color="info"
                                       href="#stow"
                                       style={{ fontSize: 12 }}
                                       onClick={(e) => e.preventDefault()}
                                    >
                                       {this.props.PropertyReducer.propertySingle.hasOwnProperty(
                                          'photos'
                                       )
                                          ? Object.keys(
                                               this.props.PropertyReducer
                                                  .propertySingle.photos
                                            ).length
                                          : 0}
                                    </Badge>
                                 </h6>
                                 <div className="pl-lg-4">
                                    <ImageCarousel
                                       visibleSlides={1}
                                       ImageData={
                                          this.props.PropertyReducer.propertySingle.hasOwnProperty(
                                             'photos'
                                          ) &&
                                          this.props.PropertyReducer
                                             .propertySingle.photos
                                       }
                                    />
                                 </div>

                                 <hr className="my-4 mt-5" />
                                 {/* Address */}

                                 <h6 className="heading-small text-muted mb-4 mt-5">
                                    Floor Plan{' '}
                                    <Badge
                                       color="info"
                                       href="#stow"
                                       style={{ fontSize: 12 }}
                                       onClick={(e) => e.preventDefault()}
                                    >
                                       {this.props.PropertyReducer.propertySingle.hasOwnProperty(
                                          'floorPlan'
                                       )
                                          ? Object.keys(
                                               this.props.PropertyReducer
                                                  .propertySingle.floorPlan
                                            ).length
                                          : 0}
                                    </Badge>
                                 </h6>
                                 <div className="pl-lg-4">
                                    <ImageCarousel
                                       visibleSlides={1}
                                       ImageData={
                                          this.props.PropertyReducer.propertySingle.hasOwnProperty(
                                             'floorPlan'
                                          ) &&
                                          this.props.PropertyReducer
                                             .propertySingle.floorPlan
                                       }
                                    />
                                 </div>
                              </div>

                              <div
                                 className="edit_form"
                                 style={{
                                    display: this.props.PropertyReducer
                                       .propertyEdit
                                       ? 'block'
                                       : 'none',
                                    width: 100 + '%',
                                 }}
                              >
                                 <hr className="my-4" />
                                 {/* Address */}

                                 <h6 className="heading-small text-muted mb-4">
                                    Contact Information
                                 </h6>
                                 <div className="pl-lg-4">
                                    <Row>
                                       <Col lg="6">
                                          <FormGroup>
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-username"
                                             >
                                                State
                                             </label>
                                             <Input
                                                className="form-control-alternative"
                                                // defaultValue="lucky.jesse"
                                                id="proState"
                                                placeholder="State"
                                                defaultValue={
                                                   this.props.PropertyReducer
                                                      .propertySingle.state
                                                }
                                                type="text"
                                             />
                                          </FormGroup>
                                       </Col>
                                       <Col lg="6">
                                          <FormGroup>
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-email"
                                             >
                                                City
                                             </label>
                                             <Input
                                                className="form-control-alternative"
                                                id="proCity"
                                                defaultValue={
                                                   this.props.PropertyReducer
                                                      .propertySingle.city
                                                }
                                                placeholder="City"
                                                type="text"
                                             />
                                          </FormGroup>
                                       </Col>
                                    </Row>
                                    <Row>
                                       <Col lg="6">
                                          <FormGroup>
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-first-name"
                                             >
                                                Location
                                             </label>

                                             <select
                                                style={{
                                                   border: '1px solid #9393f3',
                                                }}
                                                className="form-control-alternative form-control"
                                                id="proLocation"
                                             >
                                                {this.props.PropertyReducer.hasOwnProperty(
                                                   'propertyOptions'
                                                )
                                                   ? this.props.PropertyReducer.propertyOptions[0].locations.map(
                                                        (data, i) => {
                                                           return (
                                                              <option
                                                                 value={data}
                                                                 selected={
                                                                    data ==
                                                                    this.props
                                                                       .PropertyReducer
                                                                       .propertySingle
                                                                       .location
                                                                       ? 'selected'
                                                                       : null
                                                                 }
                                                              >
                                                                 {data}
                                                              </option>
                                                           );
                                                        }
                                                     )
                                                   : null}
                                             </select>
                                          </FormGroup>
                                       </Col>
                                       <Col lg="6">
                                          <FormGroup>
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-last-name"
                                             >
                                                Country
                                             </label>
                                             <Input
                                                className="form-control-alternative"
                                                //defaultValue="Jesse"
                                                id="proCountry"
                                                defaultValue={'Nigeria'}
                                                placeholder="Country"
                                                type="text"
                                             />
                                          </FormGroup>
                                       </Col>
                                    </Row>

                                    <Row>
                                       <Col md="12">
                                          <FormGroup>
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-address"
                                             >
                                                Address
                                             </label>
                                             <Input
                                                className="form-control-alternative"
                                                //defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                                                id="proAddress"
                                                defaultValue={
                                                   this.props.PropertyReducer
                                                      .propertySingle.address
                                                }
                                                placeholder="Address"
                                                rows="4"
                                                type="textarea"
                                             />
                                          </FormGroup>
                                       </Col>
                                    </Row>
                                 </div>
                                 <hr className="my-4" />
                                 {/* Address */}
                                 <h6 className="heading-small text-muted mb-4">
                                    Other information
                                 </h6>
                                 <div className="pl-lg-4">
                                    <Row>
                                       <Col lg="4">
                                          <FormGroup>
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-city"
                                             >
                                                Beds
                                             </label>

                                             <select
                                                style={{
                                                   border: '1px solid #9393f3',
                                                }}
                                                className="form-control-alternative form-control"
                                                id="proBeds"
                                             >
                                                {this.props.PropertyReducer.hasOwnProperty(
                                                   'propertyOptions'
                                                )
                                                   ? this.props.PropertyReducer.propertyOptions[0].beds.map(
                                                        (data, i) => {
                                                           return (
                                                              <option
                                                                 value={data}
                                                                 selected={
                                                                    data ==
                                                                    this.props
                                                                       .PropertyReducer
                                                                       .propertySingle
                                                                       .beds
                                                                       ? 'selected'
                                                                       : null
                                                                 }
                                                              >
                                                                 {data}
                                                              </option>
                                                           );
                                                        }
                                                     )
                                                   : null}
                                             </select>
                                          </FormGroup>
                                       </Col>
                                       <Col lg="4">
                                          <FormGroup>
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-country"
                                             >
                                                Total Units
                                             </label>
                                             <Input
                                                className="form-control-alternative"
                                                defaultValue={
                                                   this.props.PropertyReducer
                                                      .propertySingle.totalUnits
                                                }
                                                id="proUnits"
                                                placeholder="Units"
                                                type="text"
                                             />
                                          </FormGroup>
                                       </Col>
                                       <Col lg="4">
                                          <FormGroup>
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-country"
                                             >
                                                Available
                                             </label>
                                             <Input
                                                className="form-control-alternative"
                                                id="proAvailable"
                                                placeholder="Number of Units Available"
                                                defaultValue={
                                                   this.props.PropertyReducer
                                                      .propertySingle
                                                      .availableUnits
                                                }
                                                type="text"
                                             />
                                          </FormGroup>
                                       </Col>
                                    </Row>
                                    <Row>
                                       <Col lg="4">
                                          <FormGroup>
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-city"
                                             >
                                                Series
                                             </label>

                                             <select
                                                style={{
                                                   border: '1px solid #9393f3',
                                                }}
                                                className="form-control-alternative form-control"
                                                id="proSeries"
                                             >
                                                {this.props.PropertyReducer.hasOwnProperty(
                                                   'propertyFeatures'
                                                )
                                                   ? this.props.PropertyReducer.propertyFeatures.map(
                                                        (data, i) => {
                                                           return (
                                                              <option
                                                                 value={data.id}
                                                                 selected={
                                                                    data.id ==
                                                                    this.props
                                                                       .PropertyReducer
                                                                       .propertySingle
                                                                       .series
                                                                       ? 'selected'
                                                                       : null
                                                                 }
                                                              >
                                                                 {data.id}
                                                              </option>
                                                           );
                                                        }
                                                     )
                                                   : null}
                                             </select>
                                          </FormGroup>
                                       </Col>
                                       <Col lg="4">
                                          <FormGroup>
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-country"
                                             >
                                                Type
                                             </label>

                                             <select
                                                style={{
                                                   border: '1px solid #9393f3',
                                                }}
                                                className="form-control-alternative form-control"
                                                id="proType"
                                             >
                                                {this.props.PropertyReducer.hasOwnProperty(
                                                   'propertyOptions'
                                                )
                                                   ? this.props.PropertyReducer.propertyOptions[0].type.map(
                                                        (data, i) => {
                                                           return (
                                                              <option
                                                                 value={data}
                                                                 selected={
                                                                    data ==
                                                                    this.props
                                                                       .PropertyReducer
                                                                       .propertySingle
                                                                       .type
                                                                       ? 'selected'
                                                                       : null
                                                                 }
                                                              >
                                                                 {data}
                                                              </option>
                                                           );
                                                        }
                                                     )
                                                   : null}
                                             </select>
                                          </FormGroup>
                                       </Col>

                                       <Col lg="4">
                                          <FormGroup>
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-country"
                                             >
                                                Title
                                             </label>
                                             <Input
                                                className="form-control-alternative"
                                                id="proTitle"
                                                placeholder="Title"
                                                defaultValue={
                                                   this.props.PropertyReducer
                                                      .propertySingle.title
                                                }
                                                type="text"
                                             />
                                          </FormGroup>
                                       </Col>

                                       <Col lg="4">
                                          <FormGroup>
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-country"
                                             >
                                                Active
                                             </label>

                                             <select
                                                style={{
                                                   border: '1px solid #9393f3',
                                                }}
                                                className="form-control-alternative form-control"
                                                id="proActive"
                                             >
                                                <option
                                                   value={false}
                                                   selected={
                                                      this.props.PropertyReducer
                                                         .propertySingle
                                                         .active === false
                                                         ? 'selected'
                                                         : null
                                                   }
                                                >
                                                   False
                                                </option>
                                                <option
                                                   value={true}
                                                   selected={
                                                      this.props.PropertyReducer
                                                         .propertySingle
                                                         .active === true
                                                         ? 'selected'
                                                         : null
                                                   }
                                                >
                                                   True
                                                </option>

                                                <option
                                                   value={'sold out'}
                                                   selected={
                                                      this.props.PropertyReducer
                                                         .propertySingle
                                                         .active === 'sold out'
                                                         ? 'selected'
                                                         : null
                                                   }
                                                >
                                                   Sold Out
                                                </option>
                                             </select>
                                          </FormGroup>
                                       </Col>

                                       <Col lg="4">
                                          <FormGroup>
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-country"
                                             >
                                                Promo
                                             </label>

                                             <select
                                                style={{
                                                   border: '1px solid #9393f3',
                                                }}
                                                className="form-control-alternative form-control"
                                                id="proPromo"
                                             >
                                                {/* <option> -- No Promo --</option> */}

                                                <option
                                                   value={false}
                                                   selected={
                                                      this.props.PropertyReducer
                                                         .propertySingle
                                                         .promo === false
                                                         ? 'selected'
                                                         : null
                                                   }
                                                >
                                                   False
                                                </option>
                                                <option
                                                   value={true}
                                                   selected={
                                                      this.props.PropertyReducer
                                                         .propertySingle
                                                         .promo === true
                                                         ? 'selected'
                                                         : null
                                                   }
                                                >
                                                   True
                                                </option>

                                                {/*{this.props.PromoReducer.PromoactionsData &&
                                   this.props.PromoReducer.PromoactionsData.map(
                                    (data, i) => {
                                      return (
                                        <option
                                          value={data.id}
                                          selected={
                                            data.id ==
                                            this.props.PropertyReducer
                                              .propertySingle.promo
                                              ? "selected"
                                              : null
                                          }
                                        >
                                          {data.id}
                                        </option>
                                        );
                                    }
                                  )}*/}
                                             </select>
                                          </FormGroup>
                                       </Col>

                                       <Col lg="4">
                                          <FormGroup>
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-country"
                                             >
                                                Outright Price
                                             </label>
                                             <Input
                                                className="form-control-alternative"
                                                id="proPrice"
                                                placeholder="Price"
                                                defaultValue={
                                                   this.props.PropertyReducer
                                                      .propertySingle.price
                                                }
                                                type="text"
                                             />
                                          </FormGroup>
                                       </Col>
                                       <Col lg="4">
                                          <FormGroup>
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-country"
                                             >
                                                Installmental Price
                                             </label>
                                             <Input
                                                className="form-control-alternative"
                                                id="proPrice"
                                                placeholder="Price"
                                                defaultValue={
                                                   this.props.PropertyReducer
                                                      .propertySingle
                                                      .installmentPrice
                                                }
                                                type="text"
                                             />
                                          </FormGroup>
                                       </Col>
                                    </Row>

                                    <Row>
                                       <Col lg="4">
                                          <FormGroup>
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-country"
                                             >
                                                Property Name
                                             </label>
                                             <Input
                                                className="form-control-alternative"
                                                id="proName"
                                                placeholder="Property Name"
                                                defaultValue={
                                                   this.props.PropertyReducer
                                                      .propertySingle.name
                                                }
                                                type="text"
                                             />
                                          </FormGroup>
                                       </Col>

                                       <Col lg="4">
                                          <FormGroup>
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-country"
                                             >
                                                Agreement
                                             </label>

                                             <select
                                                required
                                                style={{
                                                   border: '1px solid #9393f3',
                                                }}
                                                className="form-control-alternative form-control"
                                                id="proAgreement"
                                             >
                                                <option value="">
                                                   {' '}
                                                   - Select Agreement -{' '}
                                                </option>
                                                {this.props.AgreementReducer.hasOwnProperty(
                                                   'AgreementactionsData'
                                                )
                                                   ? this.props.AgreementReducer.AgreementactionsData.map(
                                                        (data, i) => {
                                                           return (
                                                              <option
                                                                 value={data.id}
                                                                 selected={
                                                                    data.id ==
                                                                    this.props
                                                                       .PropertyReducer
                                                                       .propertySingle
                                                                       .agreement
                                                                       ? 'selected'
                                                                       : null
                                                                 }
                                                              >
                                                                 {data.name}
                                                              </option>
                                                           );
                                                        }
                                                     )
                                                   : null}
                                             </select>
                                          </FormGroup>
                                       </Col>

                                       <Col lg="4">
                                          <FormGroup>
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-country"
                                             >
                                                Shell Home
                                             </label>
                                             <select
                                                style={{
                                                   border: '1px solid #9393f3',
                                                }}
                                                className="form-control-alternative form-control"
                                                id="proShell"
                                             >
                                                {/* <option> -- No Promo --</option> */}

                                                <option
                                                   value={false}
                                                   selected={
                                                      this.props.PropertyReducer
                                                         .propertySingle
                                                         .shellHome === false
                                                         ? 'selected'
                                                         : null
                                                   }
                                                >
                                                   False
                                                </option>
                                                <option
                                                   value={true}
                                                   selected={
                                                      this.props.PropertyReducer
                                                         .propertySingle
                                                         .shellHome === true
                                                         ? 'selected'
                                                         : null
                                                   }
                                                >
                                                   True
                                                </option>
                                             </select>
                                          </FormGroup>
                                       </Col>
                                    </Row>

                                    <Row>
                                       <Col lg="4">
                                          <FormGroup>
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-first-name"
                                             >
                                                Plan (months)
                                             </label>

                                             <select
                                                style={{
                                                   border: '1px solid #9393f3',
                                                }}
                                                className="form-control-alternative form-control"
                                                id="proPlan"
                                             >
                                                {this.props.PropertyReducer.hasOwnProperty(
                                                   'propertyOptions'
                                                )
                                                   ? this.props.PropertyReducer.propertyOptions[0].Plan.map(
                                                        (data, i) => {
                                                           return (
                                                              <option
                                                                 value={data}
                                                                 selected={
                                                                    data ==
                                                                    this.props
                                                                       .PropertyReducer
                                                                       .propertySingle
                                                                       .plan
                                                                       ? 'selected'
                                                                       : null
                                                                 }
                                                              >
                                                                 {data}
                                                              </option>
                                                           );
                                                        }
                                                     )
                                                   : null}
                                             </select>
                                          </FormGroup>
                                       </Col>

                                       <Col lg="4">
                                          <FormGroup>
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-first-name"
                                             >
                                                Tentative Completion Date{' '}
                                             </label>

                                             <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                   <InputGroupText>
                                                      <i className="ni ni-calendar-grid-58" />
                                                   </InputGroupText>
                                                </InputGroupAddon>

                                                <Datetime
                                                   locale="de"
                                                   timeFormat={false}
                                                   dateFormat="MMMM YYYY"
                                                   renderDay={(
                                                      props,
                                                      currentDate,
                                                      selectedDate
                                                   ) => {
                                                      let classes =
                                                         props.className;

                                                      return (
                                                         <td
                                                            {...props}
                                                            className={classes}
                                                         >
                                                            {currentDate.date()}
                                                         </td>
                                                      );
                                                   }}
                                                   value={
                                                      this.props.PropertyReducer.propertySingle.hasOwnProperty(
                                                         'completion_date'
                                                      )
                                                         ? this.props
                                                              .PropertyReducer
                                                              .propertySingle
                                                              .completion_date
                                                         : null
                                                   }
                                                   onChange={(e) => {
                                                      this.setState({
                                                         PstartDate:
                                                            e.format(
                                                               'MMMM YYYY'
                                                            ),
                                                      });

                                                      //console.log(this.state.startDate);
                                                   }}
                                                />
                                             </InputGroup>
                                          </FormGroup>
                                       </Col>

                                       <Col lg="4">
                                          <FormGroup>
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-country"
                                             >
                                                Developer
                                             </label>

                                             <select
                                                required
                                                style={{
                                                   border: '1px solid #9393f3',
                                                }}
                                                className="form-control-alternative form-control"
                                                id="proDeveloper"
                                             >
                                                <option value="">
                                                   {' '}
                                                   - Select Developer -{' '}
                                                </option>
                                                {this.props.PropertyReducer.hasOwnProperty(
                                                   'propertyDeveloper'
                                                )
                                                   ? this.props.PropertyReducer.propertyDeveloper.map(
                                                        (data, i) => {
                                                           return (
                                                              <option
                                                                 value={data.id}
                                                                 selected={
                                                                    data.id ==
                                                                    this.props
                                                                       .PropertyReducer
                                                                       .propertySingle
                                                                       .developer
                                                                       ? 'selected'
                                                                       : null
                                                                 }
                                                              >
                                                                 {data.devName}
                                                              </option>
                                                           );
                                                        }
                                                     )
                                                   : null}
                                             </select>
                                          </FormGroup>
                                       </Col>
                                    </Row>

                                    <Row>
                                       <Col lg="4">
                                          <FormGroup>
                                             <label
                                                className="form-control-label"
                                                htmlFor="input-country"
                                             >
                                                Promo Coupon{' '}
                                             </label>

                                             <select
                                                required
                                                style={{
                                                   border: '1px solid #9393f3',
                                                }}
                                                className="form-control-alternative form-control"
                                                id="proCoupon"
                                             >
                                                <option value="">
                                                   {' '}
                                                   - Select Coupon -{' '}
                                                </option>
                                                {this.props.CouponReducer.hasOwnProperty(
                                                   'CouponactionsData'
                                                )
                                                   ? this.props.CouponReducer.CouponactionsData.map(
                                                        (data, i) => {
                                                           return (
                                                              <option
                                                                 value={data.id}
                                                                 selected={
                                                                    data.id ==
                                                                    this.props
                                                                       .PropertyReducer
                                                                       .propertySingle
                                                                       .promoCoupon
                                                                       ? 'selected'
                                                                       : null
                                                                 }
                                                              >
                                                                 {data.name}
                                                              </option>
                                                           );
                                                        }
                                                     )
                                                   : null}
                                             </select>
                                          </FormGroup>
                                       </Col>
                                    </Row>
                                 </div>
                                 <hr className="my-4" />
                                 {/* Description */}

                                 <div className="pl-lg-4">
                                    <FormGroup>
                                       <label>Status Summary</label>
                                       <Input
                                          className="form-control-alternative"
                                          placeholder="Property Summary"
                                          rows="4"
                                          id="proSummary"
                                          defaultValue={
                                             this.props.PropertyReducer
                                                .propertySingle.summary
                                          }
                                          type="textarea"
                                       />
                                    </FormGroup>
                                 </div>
                                 <Row>
                                    <Col lg="6">
                                       <Button
                                          className="float-left"
                                          style={{ marginLeft: 20 }}
                                          //color="default"
                                          href="#stow"
                                          onClick={(e) => {
                                             e.preventDefault();

                                             this.props.PropertyEdit(false);
                                          }}
                                       >
                                          Cancel Edit
                                       </Button>
                                    </Col>

                                    <Col>
                                       <Button
                                          className="float-right"
                                          style={{ marginRight: 5 }}
                                          disabled={
                                             this.state.startSavingLoader
                                          }
                                          color="success"
                                          href="#stow"
                                          onClick={(e) => {
                                             e.preventDefault();

                                             this.setState({
                                                startSavingLoader: true,
                                             });

                                             const property = {
                                                state: document.getElementById(
                                                   'proState'
                                                ).value,
                                                city: document.getElementById(
                                                   'proCity'
                                                ).value,
                                                location:
                                                   document.getElementById(
                                                      'proLocation'
                                                   ).value,
                                                country:
                                                   document.getElementById(
                                                      'proCountry'
                                                   ).value,
                                                address:
                                                   document.getElementById(
                                                      'proAddress'
                                                   ).value,

                                                beds: document.getElementById(
                                                   'proBeds'
                                                ).value,
                                                totalUnits:
                                                   document.getElementById(
                                                      'proUnits'
                                                   ).value,
                                                availableUnits:
                                                   document.getElementById(
                                                      'proAvailable'
                                                   ).value,

                                                series:
                                                   document.getElementById(
                                                      'proSeries'
                                                   ).value,

                                                type: document.getElementById(
                                                   'proType'
                                                ).value,
                                                agreement:
                                                   document.getElementById(
                                                      'proAgreement'
                                                   ).value,
                                                developer:
                                                   document.getElementById(
                                                      'proDeveloper'
                                                   ).value,

                                                promoCoupon:
                                                   document.getElementById(
                                                      'proCoupon'
                                                   ).value,

                                                //
                                                title: document.getElementById(
                                                   'proTitle'
                                                ).value,
                                                active:
                                                   document.getElementById(
                                                      'proActive'
                                                   ).value == 'true'
                                                      ? true
                                                      : false,

                                                promo:
                                                   document.getElementById(
                                                      'proPromo'
                                                   ).value == 'true'
                                                      ? true
                                                      : false,

                                                //promo: document.getElementById('proPromo').value,

                                                price: parseInt(
                                                   document.getElementById(
                                                      'proPrice'
                                                   ).value
                                                ),
                                                summary:
                                                   document.getElementById(
                                                      'proSummary'
                                                   ).value,

                                                name: document.getElementById(
                                                   'proName'
                                                ).value,

                                                shellHome:
                                                   document.getElementById(
                                                      'proShell'
                                                   ).value == 'true'
                                                      ? true
                                                      : false,

                                                plan: parseInt(
                                                   document.getElementById(
                                                      'proPlan'
                                                   ).value
                                                ),
                                                stowPlan: parseInt(
                                                   document.getElementById(
                                                      'proPlan'
                                                   ).value
                                                ),
                                                completion_date:
                                                   this.state.PstartDate,
                                             };

                                             const PropertyAll = {};

                                             var home = this;

                                             firebase
                                                .firestore()
                                                .collection('property')
                                                .doc(
                                                   home.props.PropertyReducer
                                                      .propertySingle.id
                                                )
                                                .update(property)
                                                .then(function () {
                                                   home.props.PropertyEdit(
                                                      false
                                                   );

                                                   home.setState({
                                                      startSavingLoader: false,
                                                   });

                                                   swal({
                                                      title: 'Property Updated!',
                                                      text:
                                                         'You have successfully updated ' +
                                                         home.props
                                                            .PropertyReducer
                                                            .propertySingle
                                                            .name +
                                                         ' ',
                                                      icon: 'success',
                                                      //buttons: false,
                                                   });

                                                   home.props.history.push(
                                                      '/admin/property'
                                                   );
                                                });
                                          }}
                                       >
                                          {this.state.startSavingLoader ? (
                                             // <img src={require("assets/img/loader.gif")} />
                                             <>
                                                Updating...{' '}
                                                <i className="fa fa-spinner fa-spin"></i>
                                             </>
                                          ) : (
                                             'Update Property'
                                          )}
                                       </Button>
                                    </Col>
                                 </Row>
                              </div>
                           </Form>
                        </CardBody>
                     </Card>
                  </Col>
               </Row>
            </Container>
         </>
      );
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      PropertyEdit: (param) => dispatch(PropertyEdit(param)),
      PropertySingle: (param) => dispatch(PropertySingle(param)),
      propertyActions: (param) => dispatch(propertyActions(param)),
      promoActions: (param) => dispatch(promoActions(param)),
      agreementActions: (param) => dispatch(agreementActions(param)),
      propertyProgressAction: (param) =>
         dispatch(propertyProgressAction(param)),
      couponActions: (param) => dispatch(couponActions(param)),
   };
};

const mapStateToProps = (state) => ({
   currentUser: state.authReducer,
   PropertyReducer: state.PropertyReducer,
   PromoReducer: state.PromoReducer,
   FeaturedListReducer: state.FeaturedListReducer,
   AgreementReducer: state.AgreementReducer,
   CouponReducer: state.CouponReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Property);
