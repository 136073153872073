import Dashboard from 'views/Dashboard.js';
import Payments from 'views/examples/Payments.js';
import Maps from 'views/examples/Maps.js';
import Users from 'views/examples/Users.js';
import PropertySingle from 'views/examples/PropertySingle.js';
import Property from 'views/examples/Property.js';
//Users
import Transaction from 'views/examples/Transaction.js';
import Login from 'views/examples/Login.js';
import Tables from 'views/examples/Tables.js';
import Icons from 'views/examples/Icons.js';
import FractionalProperties from 'views/examples/FractionalProperties';

var routes = [
   {
      path: '/login',
      name: 'Login',
      icon: 'ni ni-key-25 text-info',
      component: Login,
      layout: '/auth',
   },
   {
      path: '/index',
      name: 'Dashboard',
      icon: 'ni ni-tv-2 text-primary',
      component: Dashboard,
      layout: '/admin',
   },
   //
   {
      path: '/single/property',
      name: 'Property Detail',
      icon: 'ni ni-single-02 text-primary',
      component: PropertySingle,
      layout: '/admin',
   },
   {
      path: '/transaction',
      name: 'Transactions',
      icon: 'ni ni-credit-card text-primary',
      component: Transaction,
      layout: '/admin',
   },
   {
      path: '/payment',
      name: 'Payments',
      icon: 'ni ni-money-coins text-blue',
      component: Payments,
      layout: '/admin',
   },
   {
      path: '/subscribers',
      name: 'Customers',
      icon: 'ni ni-single-02 text-primary',
      component: Users,
      layout: '/admin',
   },
   {
      path: '/property',
      name: 'Property',
      icon: ' ni ni-building text-primary',
      component: Property,
      layout: '/admin',
   },
   {
      path: '/fractional-properties',
      name: 'Fractional Properties',
      icon: ' ni ni-chart-bar-32 text-primary',
      component: FractionalProperties,
      layout: '/admin',
   },

   //

   /* {
    path: "/index",
    name: "Property Features",
    icon: "ni ni-delivery-fast text-primary",
    component: Dashboard,
    layout: "/admin"
  }, */

   /* {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: Maps,
    layout: "/admin"
  }, */
   /* {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin"
  }, */
   /* {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin"
  },
   */
   /* {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  } */
];
export default routes;
