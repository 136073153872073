import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Button, Col, Modal, ModalBody, Row, Spinner, Table } from 'reactstrap';
import Styles from '../../views/coop/registration/FormData.module.css';
import firebase from 'firebase/app';

var localizedFormat = require('dayjs/plugin/localizedFormat');

dayjs.extend(localizedFormat);

export default function InvestmentDetailsModal({
   isOpen,
   data,
   closeModal,
   refreshData,
}) {
   const [investmentDetails, setInvestmentDetails] = useState(data);
   const [confirmWithdrawalLoading, setConfirmWithdrawalLoading] =
      useState(false);
   const [fetchInvestmentLoading, setFetchInvestmentLoading] = useState(false);
   const [refreshedData, setRefreshedData] = useState(false);

   console.log('investmentDetails', investmentDetails);

   const payments = [
      { name: 'firstYearPayment', ...investmentDetails.firstYearPayment },
      { name: 'secondYearPayment', ...investmentDetails.secondYearPayment },
      { name: 'thirdYearPayment', ...investmentDetails.thirdYearPayment },
      { name: 'fourthYearPayment', ...investmentDetails.fourthYearPayment },
   ];

   const getStatusClass = (status) => {
      switch (status.toLowerCase()) {
         case 'paid':
            return 'bg-success text-white';
         case 'pending':
            return 'bg-warning text-white';
         case 'overdue':
            return 'bg-danger text-white';
         default:
            return 'bg-secondary text-white';
      }
   };

   const getFormattedPaymentName = (name) => {
      switch (name) {
         case 'firstYearPayment':
            return 'First Year Payment';
         case 'secondYearPayment':
            return 'Second Year Payment';
         case 'thirdYearPayment':
            return 'Third Year Payment';
         case 'fourthYearPayment':
            return 'Fourth Year Payment';
         default:
            return 'Unknown Payment';
      }
   };

   const fetchInvestmentDetails = async () => {
      setFetchInvestmentLoading(true);
      try {
         const snapshot = await firebase
            .firestore()
            .collection('fractionalPropertiesFourYears')
            .doc(investmentDetails.fhiId)
            .collection('fractionalInvestments')
            .doc(investmentDetails.id)
            .get();
         if (snapshot.exists) {
            setInvestmentDetails(snapshot.data());
         }
         setFetchInvestmentLoading(false);
      } catch (error) {
         setFetchInvestmentLoading(false);
         console.error('Error fetching investment details', error);
      }
   };

   const onConfirmWithdrawal = async () => {
      try {
         setConfirmWithdrawalLoading(true);
         await firebase
            .firestore()
            .collection('fractionalPropertiesFourYears')
            .doc(investmentDetails.fhiId)
            .collection('fractionalInvestments')
            .doc(investmentDetails.id)
            .update({
               withdrawalRequest: null,
               availableBalance: firebase.firestore.FieldValue.increment(
                  -investmentDetails.withdrawalRequest.amount
               ),
               transactions: firebase.firestore.FieldValue.arrayUnion({
                  createdAt: new Date().toISOString(),
                  type: 'withdrawal',
                  amount: investmentDetails.withdrawalRequest.amount,
               }),
               updateAt: new Date().toISOString(),
            });
         setConfirmWithdrawalLoading(false);
         await fetchInvestmentDetails();
         setRefreshedData(true);
      } catch (error) {
         setConfirmWithdrawalLoading(false);
         console.error('Error confirming withdrawal', error);
      }
   };

   const onCloseModal = () => {
      closeModal();
      if (refreshedData) {
         refreshData();
      }
   };

   return (
      <Modal
         id="investmentDetailsModal"
         centered={true}
         fullscreen={true}
         isOpen={isOpen}
         onExit={onCloseModal}
         style={{ minWidth: 80 + '%' }}
      >
         <div className="modal-header">
            <h2 className="modal-title" id="exampleModalLabel">
               Investment Details
            </h2>

            <button
               aria-label="Close"
               className="close"
               data-dismiss="modal"
               type="button"
               onClick={(e) => {
                  closeModal();
               }}
            >
               <span aria-hidden={true}>×</span>
            </button>
         </div>
         <ModalBody>
            {fetchInvestmentLoading && (
               <Row>
                  <Col className="d-flex justify-content-center align-items-center py-7">
                     <Spinner>Loading...</Spinner>
                  </Col>
               </Row>
            )}

            {!fetchInvestmentLoading && (
               <>
                  <div className="d-flex justify-content-between">
                     <h3 className="mb-3">Payment Plan</h3>
                  </div>
                  <Row>
                     {payments.map((payment) => (
                        <Col
                           lg={3}
                           md={6}
                           key={payment.name}
                           className="card shadow-sm p-0 mb-3 border-0 rounded"
                        >
                           <div className="card-body">
                              <h5 className="card-title mb-2 fw-bold text-primary">
                                 {getFormattedPaymentName(payment.name)}
                              </h5>
                              <p className="mb-1">
                                 <strong>Amount:</strong>
                                 <span className="text-success ml-2">
                                    ₦{payment.amount.toLocaleString()}
                                 </span>
                              </p>
                              <p className="mb-1">
                                 <strong>Due Date:</strong>
                                 <span className="ml-2">
                                    {dayjs(payment.paymentDue).format(
                                       'D MMMM, YYYY'
                                    )}
                                 </span>
                              </p>
                              <p className="mb-0">
                                 <strong>Status:</strong>
                                 <span
                                    className={`badge ms-2 ml-2 ${getStatusClass(
                                       payment.paymentStatus
                                    )}`}
                                 >
                                    {payment.paymentStatus}
                                 </span>
                              </p>
                           </div>
                        </Col>
                     ))}
                  </Row>
                  <hr />
                  <div className="d-flex justify-content-between">
                     <h3 className="mb-3">Balance</h3>
                  </div>
                  <Row>
                     <Col lg={4} md={6} className="mb-4">
                        <div className={Styles.form_label}>
                           Available Balance
                        </div>
                        <div className={Styles.form_controller}>
                           {`₦${investmentDetails.availableBalance.toLocaleString()}`}
                        </div>
                     </Col>
                     <Col lg={4} md={6} className="mb-4">
                        <div className={Styles.form_label}>Total Returns</div>
                        <div className={Styles.form_controller}>
                           {`₦${investmentDetails.totalReturns.toLocaleString()}`}
                        </div>
                     </Col>
                  </Row>
                  <hr />
                  <div>
                     <h3 className="mb-3">Withdrawal Request</h3>
                     {!investmentDetails.withdrawalRequest && (
                        <h4>No withdrawal request at the moment</h4>
                     )}
                     {investmentDetails.withdrawalRequest && (
                        <div>
                           <Row>
                              <Col lg={4} md={6} className="mb-4">
                                 <div className={Styles.form_label}>
                                    Amount requested
                                 </div>
                                 <div className={Styles.form_controller}>
                                    {`₦${investmentDetails.withdrawalRequest.amount.toLocaleString()}`}
                                 </div>
                              </Col>
                              <Col lg={4} md={6} className="mb-4">
                                 <div className={Styles.form_label}>
                                    Bank name
                                 </div>
                                 <div className={Styles.form_controller}>
                                    {
                                       investmentDetails.withdrawalRequest
                                          .bankDetails.bank
                                    }
                                 </div>
                              </Col>
                              <Col lg={4} md={6} className="mb-4">
                                 <div className={Styles.form_label}>
                                    Account number
                                 </div>
                                 <div className={Styles.form_controller}>
                                    {
                                       investmentDetails.withdrawalRequest
                                          .bankDetails.accountNumber
                                    }
                                 </div>
                              </Col>
                              <Col lg={4} md={6} className="mb-4">
                                 <div className={Styles.form_label}>
                                    Account name
                                 </div>
                                 <div className={Styles.form_controller}>
                                    {
                                       investmentDetails.withdrawalRequest
                                          .bankDetails.accountName
                                    }
                                 </div>
                              </Col>
                           </Row>
                           <Row className="mt-2 mr-2 justify-content-end">
                              <Button
                                 color="success"
                                 type="submit"
                                 onClick={onConfirmWithdrawal}
                              >
                                 {confirmWithdrawalLoading ? (
                                    <>
                                       Updating...{' '}
                                       <i className="fa fa-spinner fa-spin"></i>
                                    </>
                                 ) : (
                                    'Confirm Withdrawal Payment'
                                 )}
                              </Button>
                           </Row>
                        </div>
                     )}
                  </div>
                  <hr />
                  <div>
                     <h3 className="mb-3">Transactions</h3>
                     <Table>
                        <thead>
                           <tr>
                              <th>Date</th>
                              <th>Type</th>
                              <th>Amount</th>
                           </tr>
                        </thead>
                        <tbody>
                           {investmentDetails.transactions.map(
                              (item, index) => (
                                 <tr key={index}>
                                    <th>
                                       {dayjs(item.createdAt).format(
                                          'DD-MM-YYYY'
                                       )}
                                    </th>
                                    <th>
                                       {item.type === 'investmentReturn'
                                          ? 'Investment return'
                                          : 'Withdrawal'}
                                    </th>
                                    <th
                                       className={
                                          item.type === 'investmentReturn'
                                             ? 'text-success'
                                             : 'text-danger'
                                       }
                                    >
                                       ₦{item.amount.toLocaleString()}
                                    </th>
                                 </tr>
                              )
                           )}
                        </tbody>
                     </Table>
                  </div>
               </>
            )}
         </ModalBody>
      </Modal>
   );
}
