import { isObject } from 'lodash';
import React from 'react';

// reactstrap components
import {
   Button,
   Container,
   Row,
   Col,
   Nav,
   NavItem,
   NavLink,
   UncontrolledTooltip,
   Badge,
} from 'reactstrap';

import { UserRolePermission } from './../../utils/functions';

import { PropertyEdit } from './../../store/actions/propertyActions';

import { currencyFormat } from '../../utils/functions';
import { connect } from 'react-redux';
import FIModal from './FIModal';

class UserHeader extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         property: [],
         featureList: [],
         FIModal: false,
         propertyFI: [],
      };
   }

   returnValueByID = (array, value) => {
      return array.filter(function (elem, _index) {
         return value == elem.id;
      });
   };

   render() {
      const PropertyReducer = this.props.PropertyReducer;

      return (
         <>
            <div
               className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
               style={{
                  minHeight: '600px',
                  backgroundImage:
                     'url(' +
                     (this.props.PropertyReducer.propertySingle.hasOwnProperty(
                        'primaryPhoto'
                     )
                        ? this.props.PropertyReducer.propertySingle.primaryPhoto
                             .src
                        : require('assets/img/theme/1_rVWPk6gqd64Z11U4c4Khvg.jpeg')) +
                     ')',
                  //"url(" + require("assets/img/theme/profile-cover.jpg") + ")",
                  backgroundSize: 'cover',
                  backgroundPosition: 'center top',
               }}
            >
               {/* Mask */}
               <span className="mask bg-gradient-default opacity-8" />
               {/* Header container */}
               <Container
                  className="d-flex align-items-center"
                  fluid
                  style={{ border: '0px solid white' }}
               >
                  <Row style={{ width: 100 + '%' }}>
                     <Col lg="12" md="12" style={{ border: '0px solid red' }}>
                        <h1 className="display-2 text-white">
                           {this.props.PropertyReducer.propertySingle.name}{' '}
                        </h1>
                        <p style={{ marginTop: -15 }}>
                           {this.props.PropertyReducer.propertySingle.id}
                        </p>
                        <p className="text-white mt-0 mb-5">
                           {this.props.PropertyReducer.propertySingle.address}

                           <Row style={{ marginTop: 10 }}>
                              <Col xl="12">
                                 <Nav className="nav-footer ">
                                    <NavItem
                                       id="001"
                                       style={{
                                          paddingRight: 15,
                                          fontSize: 13,
                                       }}
                                    >
                                       <i
                                          className="ni ni-world-2"
                                          style={{
                                             fontSize: 11,
                                             paddingRight: 5,
                                          }}
                                       />
                                       {
                                          this.props.PropertyReducer
                                             .propertySingle.state
                                       }
                                    </NavItem>

                                    <NavItem
                                       style={{
                                          paddingRight: 15,
                                          fontSize: 13,
                                       }}
                                    >
                                       <i
                                          className="ni ni-compass-04"
                                          style={{
                                             fontSize: 11,
                                             paddingRight: 5,
                                          }}
                                       />
                                       {
                                          this.props.PropertyReducer
                                             .propertySingle.location
                                       }
                                    </NavItem>

                                    <NavItem
                                       style={{
                                          paddingRight: 15,
                                          fontSize: 13,
                                       }}
                                    >
                                       <i
                                          className="ni ni-map-big"
                                          style={{
                                             fontSize: 11,
                                             paddingRight: 5,
                                          }}
                                       />
                                       {
                                          this.props.PropertyReducer
                                             .propertySingle.city
                                       }
                                    </NavItem>

                                    <NavItem
                                       style={{
                                          paddingRight: 15,
                                          fontSize: 13,
                                       }}
                                    >
                                       <Badge
                                          className="badge-default"
                                          style={{
                                             color: '#fff',
                                             fontSize: 11,
                                          }}
                                       >
                                          Series{' '}
                                          <code style={{ fontSize: 11 }}>
                                             {
                                                this.props.PropertyReducer
                                                   .propertySingle.series
                                             }{' '}
                                          </code>
                                       </Badge>
                                    </NavItem>

                                    {/* <NavItem style={{paddingRight:15, fontSize:13}}>             
                         <Badge  className="badge-default" style={{color:'#fff',fontSize:11}}>
                          BED <code style={{fontSize:11}}>{this.props.PropertyReducer.propertySingle.beds}</code></Badge>
                      </NavItem>


                      <NavItem style={{paddingRight:15, fontSize:13}}>             
                         <Badge  className="badge-default" style={{color:'#fff',fontSize:11}}>
                          UNIT <code style={{fontSize:11}}>{this.props.PropertyReducer.propertySingle.totalUnits}</code></Badge>
                      </NavItem>

                       <NavItem style={{paddingRight:15, fontSize:13}}>             
                         <Badge  className="badge-default" style={{color:'#fff',fontSize:11}}>
                          AVAILABLE <code style={{fontSize:11}}>{this.props.PropertyReducer.propertySingle.availableUnits}</code></Badge>
                      </NavItem> */}

                                    <NavItem
                                       style={{
                                          paddingRight: 15,
                                          fontSize: 13,
                                       }}
                                    >
                                       <Badge
                                          className="badge-default"
                                          style={{
                                             color: '#fff',
                                             fontSize: 11,
                                          }}
                                       >
                                          TYPE{' '}
                                          <code style={{ fontSize: 11 }}>
                                             {
                                                this.props.PropertyReducer
                                                   .propertySingle.type
                                             }
                                          </code>
                                       </Badge>
                                    </NavItem>

                                    <NavItem
                                       style={{
                                          paddingRight: 15,
                                          fontSize: 13,
                                       }}
                                    >
                                       <Badge
                                          className="badge-default"
                                          style={{
                                             color: '#fff',
                                             fontSize: 11,
                                          }}
                                       >
                                          TITLE{' '}
                                          <code style={{ fontSize: 11 }}>
                                             {
                                                this.props.PropertyReducer
                                                   .propertySingle.title
                                             }
                                          </code>
                                       </Badge>
                                    </NavItem>

                                    {this.props.PropertyReducer.hasOwnProperty(
                                       'propertyDeveloper'
                                    ) &&
                                    this.props.PropertyReducer.propertySingle
                                       .developer ? (
                                       <NavItem
                                          style={{
                                             paddingRight: 15,
                                             fontSize: 13,
                                          }}
                                       >
                                          <Badge
                                             className="badge-default"
                                             style={{
                                                color: '#fff',
                                                fontSize: 11,
                                             }}
                                          >
                                             DEVELOPER{' '}
                                             <code style={{ fontSize: 11 }}>
                                                {
                                                   this.returnValueByID(
                                                      this.props.PropertyReducer
                                                         .propertyDeveloper,
                                                      this.props.PropertyReducer
                                                         .propertySingle
                                                         .developer
                                                   )[0].devName
                                                }
                                             </code>
                                          </Badge>
                                       </NavItem>
                                    ) : null}

                                    <NavItem
                                       style={{
                                          paddingRight: 15,
                                          fontSize: 13,
                                       }}
                                    >
                                       {this.props.PropertyReducer
                                          .propertySingle.active ? (
                                          <Badge
                                             color="success"
                                             style={{
                                                color: '#fff',
                                                backgroundColor:
                                                   'rgb(11 204 121)',
                                             }}
                                          >
                                             <span style={{ fontSize: 11 }}>
                                                ACTIVE
                                             </span>
                                          </Badge>
                                       ) : (
                                          <Badge
                                             color="danger"
                                             style={{
                                                color: '#fff',
                                                backgroundColor:
                                                   'rgb(204 11 26)',
                                             }}
                                          >
                                             NOT ACTIVE
                                          </Badge>
                                       )}
                                    </NavItem>
                                 </Nav>

                                 <p
                                    className="h2"
                                    style={{ color: '#fff', marginTop: 20 }}
                                 >
                                    {currencyFormat(
                                       this.props.PropertyReducer.propertySingle
                                          .price
                                    )}
                                 </p>
                              </Col>
                           </Row>
                        </p>

                        <Button
                           color="info"
                           style={{
                              display: this.props.PropertyReducer.propertyEdit
                                 ? 'none'
                                 : '',
                              display: UserRolePermission(
                                 this.props.currentUser.UserData.role,
                                 'edit_prop'
                              )
                                 ? 'none'
                                 : '',
                           }}
                           disabled={UserRolePermission(
                              this.props.currentUser.UserData.role,
                              'edit_prop'
                           )}
                           href="#stow"
                           onClick={(e) => {
                              e.preventDefault();

                              //if(!(UserRolePermission(this.props.currentUser.UserData.role,'edit_prop')))
                              //{
                              this.props.PropertyEdit(true);
                              //}
                           }}
                        >
                           Edit Property
                        </Button>

                        <FIModal
                           propertyFI={this.state.propertyFI || []}
                           modalState={this.state.FIModal}
                           propertyName={
                              this.props.PropertyReducer.propertySingle.name
                           }
                           propertyid={
                              this.props.PropertyReducer.propertySingle.id
                           }
                           propertyPrice={
                              this.props.PropertyReducer.propertySingle.price
                           }
                           propertyPrimaryPhoto={
                              this.props.PropertyReducer.propertySingle
                                 .primaryPhoto
                           }
                           propertyCity={
                              this.props.PropertyReducer.propertySingle.city
                           }
                           propertyState={
                              this.props.PropertyReducer.propertySingle.state
                           }
                           propertyType={
                              this.props.PropertyReducer.propertySingle.type
                           }
                           propertyBeds={
                              this.props.PropertyReducer.propertySingle.beds
                           }
                           toggleModal={() => {
                              this.setState({ FIModal: !this.state.FIModal });
                           }}
                        />
                        <Button
                           color="secondary"
                           style={{
                              display: this.props.PropertyReducer.propertyEdit
                                 ? 'none'
                                 : '',
                              display: UserRolePermission(
                                 this.props.currentUser.UserData.role,
                                 'edit_prop'
                              )
                                 ? 'none'
                                 : '',
                           }}
                           disabled={UserRolePermission(
                              this.props.currentUser.UserData.role,
                              'edit_prop'
                           )}
                           href="#stow"
                           onClick={(e) => {
                              e.preventDefault();

                              //  console.log(this.props.FI_Reducer);
                              // console.log(Object.keys(this.props.FI_Reducer?.propFI_TR).length);

                              this.setState(
                                 {
                                    propertyFI:
                                       this.props.FI_Reducer?.propFI_TR,
                                 },
                                 () => {
                                    // console.log(JSON.stringify(this.state.propertyFI)+"===");
                                    this.setState({ FIModal: true });
                                 }
                              );
                           }}
                        >
                           Fractional Investment
                        </Button>

                        <span style={{ fontSize: 19 }}></span>
                     </Col>
                  </Row>
               </Container>
            </div>
         </>
      );
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      PropertyEdit: (param) => dispatch(PropertyEdit(param)),
      // PropertySingle: (param) => dispatch(PropertySingle(param))
   };
};

const mapStateToProps = (state) => ({
   currentUser: state.authReducer,
   PropertyReducer: state.PropertyReducer,
   FI_Reducer: state.FI_Reducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserHeader);
