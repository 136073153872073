import Header from 'components/Headers/Header';
import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { Button, Card, Col, Container, Row, Spinner } from 'reactstrap';
import MaterialTable, { MTableCell, MTableToolbar } from 'material-table';
import CountUp from 'react-countup';
import { useHistory } from 'react-router-dom';

export default function FractionalProperties() {
   const [properties, setProperties] = useState([]);
   const [loading, setLoading] = useState(true);

   console.log('properties', properties);

   const history = useHistory();

   const fetchProperties = async () => {
      try {
         const snapshot = await firebase
            .firestore()
            .collection('fractionalPropertiesFourYears')
            .get();
         const fetchedProperties = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
         }));
         setProperties(fetchedProperties);
         setLoading(false);
      } catch (error) {
         setLoading(false);
         console.error('error fetching properties', error);
      }
   };

   useEffect(() => {
      fetchProperties();
   }, []);

   return (
      <>
         <Header />
         <Container className="mt--7" fluid>
            <Row>
               <div className="col">
                  <Card className="shadow border-0">
                     <div style={{ maxWidth: '100%', borderRadius: 20 }}>
                        {loading && (
                           <Row>
                              <Col className="d-flex justify-content-center align-items-center py-5">
                                 <Spinner>Loading...</Spinner>
                              </Col>
                           </Row>
                        )}
                        {!loading && fetchProperties.length === 0 && (
                           <MaterialTable
                              columns={[
                                 {
                                    title: 'Name',
                                    field: 'name',
                                    render: (rowData) => {
                                       return rowData.name;
                                    },
                                 },
                                 {
                                    title: 'FHI price',
                                    field: 'fhiUnitPrice',
                                    render: (rowData) => {
                                       return `₦${rowData.fhiUnitPrice.toLocaleString()}`;
                                    },
                                 },
                                 {
                                    title: 'Available Units',
                                    field: 'availableUnits',
                                    render: (rowData) => {
                                       return `${rowData.availableUnits}/${rowData.noOfFractionalUnits}`;
                                    },
                                 },
                                 {
                                    title: 'Annual Yield',
                                    field: 'annualYield',
                                    render: (rowData) => {
                                       return `₦${rowData.annualYield.toLocaleString()}  (${
                                          rowData.annualYieldPercentage
                                       }%)`;
                                    },
                                 },
                                 {
                                    title: 'Capital Gain',
                                    field: 'capitalGain',
                                    render: (rowData) => {
                                       return `₦${rowData.capitalGain.toLocaleString()}   (${
                                          rowData.capitalGainPercentage
                                       }%)`;
                                    },
                                 },
                                 {
                                    title: 'Created by',
                                    field: 'createdBy',
                                    render: (rowData) => {
                                       return rowData.createdBy;
                                    },
                                 },
                              ]}
                              actions={[
                                 {
                                    icon: (row) => (
                                       <Button
                                          color="primary"
                                          href="#"
                                          style={{ fontSize: 9 }}
                                          size="sm"
                                       >
                                          <i className="fa fa-eye" />
                                       </Button>
                                    ),

                                    tooltip: 'View property investments',

                                    onClick: (event, row) => {
                                       history.push(
                                          `/admin/fractional-investment/${row.id}`
                                       );
                                    },
                                 },
                              ]}
                              data={properties}
                              options={{
                                 exportButton: true,
                                 exportFileName: 'Property_Data_' + new Date(),
                                 //grouping: true,
                                 pageSize: 10,
                                 pageSizeOptions: [
                                    5, 10, 20, 30, 50, 75, 100, 200, 500, 1000,
                                    2000, 3000, 5000, 8000, 10000,
                                 ],
                                 toolbar: true,
                                 // paging: true,

                                 rowStyle: {
                                    paddingLeft: '1.5rem',
                                    paddingRight: '1.5rem',
                                    verticalAlign: 'middle',

                                    fontSize: '0.8125rem',
                                    whiteSpace: 'nowrap',
                                    padding: '1rem',
                                    borderTop: '1px solid #e9ecef',
                                    fontFamily: 'Open Sans, sans-serif',
                                 },

                                 headerStyle: {
                                    paddingTop: '0.75rem',
                                    paddingBottom: '0.75rem',
                                    fontSize: '0.65rem',
                                    textTransform: 'uppercase',
                                    letterPpacing: '1px',
                                    borderBottom: '1px solid #e9ecef',
                                    fontWeight: 'bolder',
                                    paddingLeft: '1.5rem',
                                    paddingRight: '1.5rem',
                                    backgroundColor: '#f6f9fc',
                                    color: '#8898aa',
                                    verticalAlign: 'middle',
                                    borderColor: '#e9ecef',
                                    fontFamily: 'Open Sans, sans-serif',
                                 },
                              }}
                              components={{
                                 Toolbar: (props) => (
                                    <p className="h3" style={{ padding: 10 }}>
                                       <MTableToolbar {...props} />
                                       <div style={{ marginTop: -40 }}>
                                          {'Total'}{' '}
                                          <CountUp
                                             end={
                                                Object.keys(properties).length
                                             }
                                          />
                                       </div>
                                    </p>
                                 ),

                                 Cell: (props) => {
                                    if (props.columnDef.field === 'tid') {
                                       //return <MTableCell {...props} id={'cell_'+(props.rowData.hasOwnProperty('r_txnref')?props.rowData.r_txnref:props.rowData.tid)} />
                                       return <MTableCell {...props} />;
                                    } else {
                                       return (
                                          <MTableCell
                                             {...props}
                                             data={JSON.stringify(
                                                props.columnDef
                                             )}
                                          />
                                       );
                                    }
                                 },
                              }}
                              title={''}
                           />
                        )}
                     </div>
                  </Card>
               </div>
            </Row>
         </Container>
      </>
   );
}
